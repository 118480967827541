<template>
  <div class="w-full px-8 py-3 md:py-6">
    <section class="w-full max-w-7xl md:mx-auto">
      <!-- Syllabus,Structure,Eligibility start -->
      <div class="py-5">
        <!-- <div class="pt-3 lg:pt-5"> -->
        <div class="flex gap-2 lg:gap-4">
          <div
            v-for="program in courseDetails"
            :key="program"
            class="flex flex-wrap gap-2 lg:gap-5"
          >
            <button
              @click="activeCourse = program"
              :class="{
                'border-[#0CB1EF] text-[#0CB1EF] text-[14px] lg:text-[18px]':
                  activeCourse === program,
                '': activeCourse !== program,
              }"
              v-html="program"
              class="px-4 lg:px-4 py-[8px] text-left lg:text-center text-[14px] lg:text-[18px] font-medium shadow border rounded-lg"
            ></button>
          </div>
        </div>
        <div class="py-6 lg:py-8">
          <!-- Syllabus start -->
          <div v-if="activeCourse === 'Syllabus'" class="hidden md:block">
            <div class="flex justify-start flex-wrap gap-4" v-if="this.elective">
              <!--  Semester -->
              <div
                class="flex flex-col gap-3 p-4 bg-white courseShadow text-left w-full lg:w-[365px] px-6"
                v-for="el in elective.syllabus"
                :key="el.id"
              >
                <h2 class="text-[18px] text-[#18479E] font-bold">
                  {{ el.semester }}
                </h2>
                <div
                  class=" text-[14px] flex flex-col gap-1 p-2"
                >
                  <span class="elctive" v-html="el.content"></span>
                </div>
              </div>
              <!--  Semester -->
            </div>
          </div>
          <div v-if="activeCourse === 'Syllabus'" class="md:hidden">
            <div
              class="w-full flex flex-col gap-2 py-[10px] md:pt-6"
              v-if="this.elective"
            >
              <div
                v-for="(item, index) in elective.syllabus"
                :key="index"
                @click="toggleAccordin(index)"
                class="flex flex-col text-left px-[30px] py-[11px] md:px-[25px] cursor-pointer md:py-[20px] gap-2 bg-white border rounded-lg shadow-xl"
              >
                <div class="flex justify-between items-start cursor-pointer">
                  <h3
                    class="text-[14px] md:text-[16px] lg:text-[16px] text-[#18479E] font-bold"
                  >
                    {{ item.semester }}
                  </h3>
                  <div>
                    <span
                      v-if="this.activeAccordin !== index"
                      class="pt-[3px] pl-[4px]"
                    >
                      <font-awesome-icon icon="fa-angle-down" class="" />
                    </span>
                    <span v-else class="pt-[3px] pl-[4px]">
                      <font-awesome-icon icon="fa-angle-up" class="" />
                    </span>
                  </div>
                </div>
                <!-- <div class="flex gap-2">
                    <img
                        src="@/assets/electivepage/check.svg"
                        alt="check image"
                      />
                  </div> -->
                <div v-show="activeAccordin === index">
                  <p class="flex gap-2 elctive" v-html="item.content"></p>
                </div>
                <!-- <p
                    class="text-[12px] lg:text-[14px] w-[266px] md:w-[500px] lg:w-[657px] text-[#1f1f1f]"
                    v-show="activeAccordin === index"
                    v-html="item.details"
                  ></p> -->
              </div>
              <!-- <div
                  class="w-full md:flex md:justify-center md:items-center mt-2 lg:mt-3 hidden"
                >
                  <button
                    @click="viewMore"
                    v-if="showMore"
                    class="w-full md:w-[160px] button-cta bg-[#f8c300] border rounded-3xl py-3"
                  >
                    Load More
                  </button>
                  <button
                    @click="viewLess"
                    v-else
                    class="w-full md:w-[160px] button-cta bg-[#f8c300] border rounded-3xl py-3"
                  >
                    Load Less
                  </button>
                </div> -->
            </div>
          </div>
          <!-- Syllabus end -->
          <!-- Fee Structure start -->
          <div v-if="activeCourse === 'Fee Structure'">
            <div class="flex flex-col gap-3 md:flex-row md:justify-center">
              <!-- indian students  -->
              <div
                class="flex flex-col gap-1 p-5 lg:px-5 lg:py-6 bg-white courseShadow text-left w-full lg:w-[500px]"
              >
                <h2 class="text-[18px] lg:text-[22px] font-bold uppercase">
                  indian students
                </h2>
                <p class="text-[14px]">For Indian National & SAARC Nations</p>
                <div
                  class="border-b-2 border-slate-200 py-3 text-black font-semibold"
                >
                  <!-- <div class="flex justify-between">
                      <p>Semester Fee Plan</p>
                      <p class="text-[#18479e] text-[14px] font-bold">
                        ₹ {{ elective.indian_semester_fee }}
                      </p>
                    </div> -->
                  <div class="flex justify-between">
                    <p>Annual Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      ₹ {{ elective.indian_annual_fee }}
                    </p>
                  </div>
                  <div class="flex justify-between">
                    <p>Total Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      ₹ {{ elective.indian_total_fee }}
                    </p>
                  </div>
                </div>
                <p class="text-black font-bold text-[14px] py-2">Note</p>
                <div class="flex flex-col gap-2">
                  <p>
                    University Registration Fee:<span
                      class="text-black font-bold text-[14px]"
                    >
                      ₹ {{ elective.indian_university_registration_fee }}</span
                    >
                  </p>
                  <p>
                    Examination Fee per year:
                    <span class="text-black font-bold text-[14px]">
                      ₹ {{ elective.indian_exam_fee }}</span
                    >
                  </p>
                  <p>
                    Registration Fee for SAARC countries:
                    <span class="text-black font-bold text-[14px]"
                      >₹ {{ elective.indian_registration_fee_for_saarc }}</span
                    >
                  </p>
                  <p>
                    Exam Fee Per Year for SAARC countries:
                    <span class="text-black font-bold text-[14px]"
                      >₹ {{ elective.indian_exam_fee_for_saarc }}</span
                    >
                  </p>
                </div>
              </div>
              <!-- foreign students -->
              <div
                class="flex flex-col gap-1 p-5 lg:px-5 lg:py-6 bg-white courseShadow text-left w-full lg:w-[500px]"
              >
                <h2 class="text-[18px] lg:text-[22px] font-bold">
                  FOREIGN STUDENTS
                </h2>
                <p class="text-[14px]">For Foreign Students</p>
                <div
                  class="border-b-2 border-slate-200 py-3 text-black font-semibold"
                >
                  <!-- <div class="flex justify-between">
                      <p>Semester Fee Plan</p>
                      <p class="text-[#18479e] text-[14px] font-bold">
                        $ {{ elective.foregin_semester_fee }}
                      </p>
                    </div> -->
                  <div class="flex justify-between">
                    <p>Annual Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      $ {{ elective.foregin_annual_fee }}
                    </p>
                  </div>
                  <div class="flex justify-between">
                    <p>Total Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      $ {{ elective.foregin_total_fee }}
                    </p>
                  </div>
                </div>
                <p class="text-black font-bold text-[14px] py-2">Note</p>
                <div class="flex flex-col gap-2">
                  <p>
                    University Registration Fee:<span
                      class="text-black font-bold text-[14px]"
                      >$
                      {{ elective.foregin_university_registration_fee }}</span
                    >
                  </p>
                  <p>
                    Examination Fee per year:
                    <span class="text-black font-bold text-[14px]"
                      >$ {{ elective.foregin_exam_fee }}</span
                    >
                    is applicable
                  </p>
                  <!-- <p>
                      Registration Fee for SAARC countries:
                      <span class="text-black font-semibold">₹ 3,750.00</span>
                    </p>
                    <p>
                      Exam Fee Per Year for SAARC countries:
                      <span class="text-black font-semibold">₹ 4,500.00</span>
                    </p> -->
                </div>
              </div>
            </div>
          </div>
          <!-- Fee Structure end -->

          <!-- Eligibility start -->
          <div
            v-if="activeCourse === 'Eligibility'"
            class="flex flex-col gap-3 p-4 text-left"
          >
            <div
              class="flex flex-col gap-5 lg:flex-row w-full lg:max-w-6xl lg:mx-auto"
            >
              <div class="w-full lg:w-1/2">
                <img
                  src="@/assets/electivepage/eligibility.webp"
                  alt="eligibility"
                />
              </div>
              <div
                class="w-full lg:w-1/2 text-left flex justify-center items-center"
              >
                <p class="text-[14px]">
                  Pass the (10+2) examination from State Board / CBSE / NIOS / IGCSE / IB / ICSE recognized by the State or Central Government.
                </p>
              </div>
            </div>
          </div>
          <!-- Eligibility end -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AdvancedCertificate",
  props: ["elective"],
  data() {
    return {
      courseDetails: ["Syllabus", "Fee Structure", "Eligibility"],
      activeCourse: "Syllabus",
      activeAccordin: "1st Semester",
      faqToShow: 6,
    };
  },
  computed: {
    // faqItem() {
    //   return this.elective.syllabus.slice(0, this.faqToShow);
    // },
    // showMore() {
    //   return this.faqToShow <= this.syllabus.length;
    // },
  },
  methods: {
   
    toggleAccordin(index) {
      if (index === this.activeAccordin) {
        this.activeAccordin = null;
      } else {
        this.activeAccordin = index;
      }
    },
    // viewMore() {
    //   this.faqToShow += 2;
    // },
    // viewLess() {
    //   this.faqToShow = 3;
    // },
  },
};
</script>

<style scoped>
.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.syllabusbullet ul {
	list-style-image: url("https://api.shardaonline.ac.in/media/Star_57347.webp") !important;
}
.syllabusbullet li > span {
  position: relative;
  top: -5px;
}
.courseShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
