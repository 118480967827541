<template>
  <div class="w-full bg-[#f7f9fb] py-10">
    <div class="max-w-7xl mx-auto w-full px-3 ">
      <div class="flex lg:flex-row flex-col text-center justify-center">
        <h1
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize"
        >
          Explore Our Online Degree Programs
        </h1>
      </div>

      <div>
        <div
          class="alltabs flex flex-row justify-center gap-[10px] lg:gap-[20px] pt-10 pb-10"
        >
          <button
            :class="{ active: currentSlider === 1 }"
            class="tabbutton md:min-w-[165px] w-[150px] lg:w-[180px] xl:w-[100px] py-2 md:min-h-[60px] text-black"
            @click="
              showSlider(1);
              allProgramInfo();
            "
          >
            All
          </button>
          <button
            :class="{ active: currentSlider === 2 }"
            class="tabbutton md:min-w-[165px] w-[150px] lg:w-[180px] xl:w-[100px] py-2 md:min-h-[60px] text-black"
            @click="programInfo('Masters', 2)"
          >
            MASTERS
          </button>
          <button
            :class="{ active: currentSlider === 3 }"
            class="tabbutton md:min-w-[165px] w-[150px] lg:w-[180px] xl:w-[100px] py-2 md:min-h-[60px] text-black"
            @click="programInfo('Bachelors', 3)"
          >
            BACHELORS
          </button>
        </div>

        <div v-if="currentSlider === 1">
          <div ref="slider1" class="slider justify-center m-auto">
            <div
              v-for="item in allProgramData"
              :key="item.id"
              class="slide-item"
            >
              <!-- Your provided code goes here -->
              <div
                class="flex flex-col gap-5 lg:w-[345px] lg:h-[425px] w-[100%] mx-auto border-2 justify-center rounded-lg"
              >
                <div>
                  <img
                    :src="item.image"
                    :alt="item.name"
                    width="390px"
                    height="240px"
                    class="w-[100%]"
                  />
                </div>
                <div class="flex flex-col gap-5 p-4">
                  <p class="text-[16px] h-[60px] font-bold text-left">
                    {{ item.sub_name }} {{ item.name }}
                    <span class="text-primary">{{ item.subtitle }}</span>
                  </p>
                  <div class="flex gap-4">
                    <div class="flex items-center gap-2">
                      <img
                        src="@/assets/elective/calender.webp"
                        alt=""
                        class="h-[24px] w-[24px]"
                      />
                      <p class="text-[#4D4D4D] text-[15px] text-left">
                        Live Online Sessions
                      </p>
                    </div>
                    <div class="flex items-center gap-2">
                      <img
                        src="@/assets/elective/watch.webp"
                        alt=""
                        class="h-[23px] w-[23px]"
                      />
                      <p class="text-[#4D4D4D] text-[15px]">
                        {{ item.durations }} Years
                      </p>
                    </div>
                  </div>
                  <a :href="item.shot_slug">
                    <div
                      class="bg-[#0CB1EF] text-white text-[13px] hover:text-[#18469d] py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#0CB1EF] flex justify-center items-center"
                    >
                      <p class="uppercase font-semibold">GET STARTED</p>
                    </div>
                  </a>
                </div>
              </div>
              <!-- End of provided code -->
            </div>
          </div>
        </div>

        <div v-else-if="currentSlider === 2">
          <div ref="slider2" class="slider">
            <div v-for="item in programData" :key="item.id" class="slide-item">
              <!-- Your provided code goes here -->
              <div
                class="flex flex-col gap-5 lg:w-[345px] lg:h-[425px] w-[100%] mx-auto border-2 justify-center rounded-lg"
              >
                <div>
                  <img
                    :src="item.image"
                    :alt="item.name"
                    width="390px"
                    height="240px"
                    class="w-[100%]"
                  />
                </div>
                <div class="flex flex-col gap-5 p-4">
                  <p class="text-[16px] h-[60px] font-bold text-left">
                    {{ item.sub_name }} {{ item.name }}
                    <span class="text-primary">{{ item.subtitle }}</span>
                  </p>
                  <div class="flex gap-4">
                    <div class="flex items-center gap-2">
                      <img
                        src="@/assets/elective/calender.webp"
                        alt=""
                        class="h-[24px] w-[24px]"
                      />
                      <p class="text-[#4D4D4D] text-[15px] text-left">
                        Live Online Classes
                      </p>
                    </div>
                    <div class="flex items-center gap-2">
                      <img
                        src="@/assets/elective/watch.webp"
                        alt=""
                        class="h-[23px] w-[23px]"
                      />
                      <p class="text-[#4D4D4D] text-[15px]">
                        {{ item.durations }} years
                      </p>
                    </div>
                  </div>

                  <a :href="item.shot_slug">
                    <div
                      class="bg-[#0CB1EF] text-white text-[13px] hover:text-[#18469d] py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#0CB1EF] flex justify-center items-center"
                    >
                      <p class="uppercase font-semibold">GET STARTED</p>
                    </div>
                  </a>
                </div>
              </div>
              <!-- End of provided code -->
            </div>
          </div>
        </div>

        <div v-else-if="currentSlider === 3">
          <div ref="slider3" class="slider">
            <div v-for="item in programData" :key="item.id" class="slide-item">
              <div
                class="flex flex-col gap-5 lg:w-[345px] lg:h-[425px] w-[100%] mx-auto border-2 justify-center rounded-lg"
              >
                <div>
                  <img
                    :src="item.image"
                    :alt="item.name"
                    width="390px"
                    height="240px"
                    class="w-[100%]"
                  />
                </div>
                <div class="flex flex-col gap-5 p-4">
                  <p class="text-[16px] h-[60px] font-bold text-left">
                    {{ item.sub_name }} {{ item.name }}
                    <span class="text-primary">{{ item.subtitle }}</span>
                  </p>
                  <div class="flex gap-4">
                    <div class="flex items-center gap-2">
                      <img
                        src="@/assets/elective/calender.webp"
                        alt=""
                        class="h-[24px] w-[24px]"
                      />
                      <p class="text-[#4D4D4D] text-[15px] text-left">
                        Live Online Classes
                      </p>
                    </div>
                    <div class="flex items-center gap-2">
                      <img
                        src="@/assets/elective/watch.webp"
                        alt=""
                        class="h-[23px] w-[23px]"
                      />
                      <p class="text-[#4D4D4D] text-[15px]">
                        {{ item.durations }} years
                      </p>
                    </div>
                  </div>
                  <a :href="item.shot_slug">
                    <div
                      class="bg-[#0CB1EF] text-white text-[13px] hover:text-[#18469d] py-2 rounded-[50px] w-40 hover:bg-white hover:border-2 hover:border-[#0CB1EF] flex justify-center items-center"
                    >
                      <p class="uppercase font-semibold">GET STARTED</p>
                    </div>
                  </a>
                </div>
              </div>
              <!-- End of provided code -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
// import axios from "axios";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
export default {
  name: "DegreeView",
  data() {
    return {
      activetabbutton: 0,
      currentSlider: 1,
      slickInstances: {}, // Store slick instances for each slider
      programData: [],
      allProgramData: [],
    };
  },

 
  mounted() {
    this.allProgramInfo();
  },
  beforeUnmount() {
    // Clean up slick instances when the component is destroyed
    Object.values(this.slickInstances).forEach((slickInstance) => {
      this.destroySlider(slickInstance);
    });
  },

  props: ["elective"],

  methods: {
    async allProgramInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/course/elective-list/`)
        .then((resp) => {
          this.allProgramData = resp.data.data;
          setTimeout(() => {
            this.initializeSlider(1);
          }, 100);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async programInfo(name, sliderNumber) {
      this.currentSlider = 0;
      await axios
        .get(
          `${process.env.VUE_APP_API}/course/elective-list/?program__category__name=${name}`
        )
        .then((resp) => {
          this.programData = resp.data.data;
          this.currentSlider = sliderNumber;
          if (name == "Masters" && this.programData.length > 0) {
            setTimeout(() => {
              this.initializeSlider(2);
            }, 100);
          } else {
            setTimeout(() => {
              this.initializeSlider(3);
            }, 100);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showSlider(sliderNumber) {
      this.currentSlider = sliderNumber;
    },
    initializeSlider(sliderNumber) {
      const refName = `slider${sliderNumber}`;
      this.slickInstances[sliderNumber] = $(this.$refs[refName]).slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        rows: 1,
        dots: true,
        dotsClass: "slick-dots",
        prevArrow: false,
        nextArrow: false,
        autoplaySpeed: 3000,
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1026,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    },

    destroySlider(sliderNumber) {
      const slickInstance = this.slickInstances[sliderNumber];
      if (slickInstance) {
        slickInstance.slick("unslick");
        delete this.slickInstances[sliderNumber];
      }
    },
  },
};
</script>

<style lang="scss">
.tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.tabbutton {
  cursor: pointer;
  user-select: none;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.tabbutton:hover {
  border-bottom: 3px solid #18469d;
}

.tabbutton-content > div {
  display: none;
  padding: 20px 20px;
}

.tabbutton-content > div.show {
  display: block;
  border-radius: 10px;
}

.active {
  border-bottom: 3px solid #18469d;
}

.slide {
  display: flex;
  justify-content: center;
}

.slick-wrapper {
  width: 100%;
}

.slick-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}

.slick-dots li {
  margin: 0 0.25rem;
}

.slick-dots button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}

.slick-dots li.slick-active button {
  background-color: #18469d;
  width: 0.75rem;
  height: 0.75rem;
}
</style>
