<template>
  <div>
    <BannerView v-if="this.programData" :program="this.programData" />
    <AboutView />
    <AwardsView />
    <OnlineProgram v-if="this.programData" :program="this.programData" />
    <HiglightView />
    <WhoApply v-if="this.programData" :program="this.programData" />
    <HiringView />
    <CareerSupport />
    <FaqView v-if="this.lpProgramfaq" :faq="this.lpProgramfaq" />
    <div class="pb-[80px] lg:pb-0">
      <FooterView />
    </div>
    <LpStickyView />
  </div>
</template>

<script>
import BannerView from "@/components/LP_ProgramsThankyou/BannerView.vue";
import AboutView from "@/components/LP_Programs/AboutView.vue";
import AwardsView from "@/components/LP_Programs/AwardsView.vue";
import OnlineProgram from "@/components/LP_Programs/OnlineProgram.vue";
import HiglightView from "@/components/LP_Programs/HiglightView.vue";
import WhoApply from "@/components/LP_Programs/WhoApply.vue";
import HiringView from "@/components/LP_Programs/HiringView.vue";
import CareerSupport from "@/components/LP_Programs/CareerSupport.vue";
import FaqView from "@/components/LP_Programs/FaqView.vue";
import FooterView from "@/components/LP_Programs/FooterView.vue";
import LpStickyView from "@/components/LpStickyView.vue";
import axios from "axios";

export default {
  name: "LP_programsThankyou",
  components: {
    BannerView,
    AboutView,
    AwardsView,
    OnlineProgram,
    HiglightView,
    WhoApply,
    HiringView,
    CareerSupport,
    FaqView,
    FooterView,
    LpStickyView,
  },
  data() {
    return {
      programData: [],
      lpProgramfaq: [],
    };
  },
  props: ["slug"],
  created() {
    this.programInfo();
    this.programFaq();
  },
  methods: {
    async programInfo() {
      await axios
        .get(`${process.env.VUE_APP_API}/lp/electives/?slug=${this.slug}`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.programData = resp.data.data[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async programFaq() {
      await axios
        .get(`${process.env.VUE_APP_API}/lp/electives/faq`)
        .then((resp) => {
          if (resp.data.status == 200) {
            this.lpProgramfaq = resp.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
