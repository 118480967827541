<template>
  <div class="py-5 px-8">
    <div class="w-full max-w-7xl mx-auto">
      <div>
        <h1
          class="text-[24px] lg:text-[34px] text-[#18479e] font-bold text-center"
        >
          Explore Categories
        </h1>
      </div>
      <!-- Category dropdown -->
      <!-- desktop -->
      <div class="hidden lg:block mt-4">
        <div class="flex flex-row gap-[40px] xl:gap-[80px]">
          <div
            class="category-option rounded-md px-[50px] py-2"
            :class="{ 'active-category': !selectedCategory }"
            @click="handleCategoryChange(null)"
          >
            All
          </div>
          <div
            v-for="category in BlogsPost"
            :key="category.id"
            class="category-option rounded-md px-[10px] py-2"
            :class="{ 'active-category': selectedCategory === category.title }"
            @click="handleCategoryChange(category.title)"
          >
            {{ category.title }}
          </div>
        </div>
      </div>
      <!-- mobile -->
      <div class="block lg:hidden">
        <select
          v-model="selectedCategory"
          @change="handleCategoryChange(selectedCategory)"
          class="category-dropdown rounded-md w-full px-5 border border-[#0CB1EF] bg-white text-[#0CB1EF] py-2"
        >
          <option value="">All</option>
          <option
            v-for="category in BlogsPost"
            :key="category.id"
            :value="category.title"
          >
            {{ category.title }}
          </option>
        </select>
      </div>

      <!-- Display blogs in a responsive grid -->
      <div
        v-for="(row, rowIndex) in chunkedBlogs"
        :key="rowIndex"
        class="flex flex-wrap gap-8 my-8 container justify-start align-middle mx-auto "
      >
        <div
          v-for="item in row"
          :key="item.id"
          class="w-full md:w-[335px] lg:w-[280px] h-[400px] "
        >
          <!-- <div class="bg-white rounded shadow-md p-4">
          <a :href="`/blogs/${item.slug}`">
            <img :src="item.image" alt="" class="w-full h-auto mb-2 rounded" />
          </a>
          <h1 class="text-[16px] text-black font-bold text-left">
            {{ item.title }}
          </h1>
          <p class="text-left text-black text-sm mb-2">
            {{ formatDate(item.publish_at) }}
          </p>
          <p class="text-left text-black text-sm mb-4">
            {{ item.description }}
          </p>
          <p
            class="text-left font-semibold text-sm text-[#004271] hover:text-[#0d6efd]"
          >
            <a :href="`/blogs/${item.slug}`">Read more</a>
          </p>
        </div> -->
          <!-- Your provided code goes here -->
          <div class="flex flex-col gap-3 border rounded-lg h-full">
            <a :href="`/blogs/${item.slug}`">
              <div>
                <img :src="item.image" class="lg:w-[280px] h-[150px]" />
              </div>
            </a>
            <div class="px-2 flex flex-col gap-2 text-left">
              <a :href="`/blogs/${item.slug}`"><h1 class="text-[16px] text-black font-bold">
                {{ item.title }}
              </h1></a>
              <div class="flex gap-7 pt-3">
                <p class="text-[#454545] text-xs text-start font-semibold">
                  {{ formatDate(item.publish_at) }}
                </p>
                <ul class="text-[#454545] text-xs text-start font-semibold">
                  <li class="list-disc">{{ item.category.title }}</li>
                </ul>
              </div>
              <p class="text-[12px]">
                {{ item.description }}
              </p>

              <a :href="`/blogs/${item.slug}`">
                <div class="flex gap-2 items-center">
                  <p class="text-[14px] lg:text-[16px] text-[#0CB1EF]">
                    Read more
                  </p>
                  <img
                    src="@/assets/BlogsLanding/right-up.png"
                    alt=""
                    srcset=""
                    class="w-5 h-5"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Pagination -->
      <div class="flex items-center justify-center mt-4">
        <div>
          <!-- First button
        <button
          @click="changePage(1)"
          :disabled="currentPage === 1"
          class="mr-2"
        >
          First
        </button> -->
          <!-- Prev button -->
          <button
            @click="changePage('prev')"
            :disabled="currentPage === 1"
            class="border px-2 py-1"
          >
            <font-awesome-icon icon="fa-solid  fa-angle-left" class="" />
          </button>
          <!-- Page numbers -->
          <template v-for="page in displayedPages" :key="page">
            <button
              @click="changePage(page)"
              :class="{
                'font-bold': currentPage === page,
                'active-page': currentPage === page,
              }"
              class="border px-2 py-1"
            >
              {{ page }}
            </button>
          </template>
          <!-- Next button -->
          <button
            @click="changePage('next')"
            :disabled="currentPage === totalPages"
            class="border px-2 py-1"
          >
            <font-awesome-icon icon="fa-solid  fa-angle-right" class="" />
          </button>
          <!-- Last button
        <button
          @click="changePage(totalPages)"
          :disabled="currentPage === totalPages"
          class="ml-2"
        >
          Last
        </button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      selectedCategory: "",
      originalBlogs: [],
      Blogs: [],
      BlogsPost: [],
      currentPage: 1,
      totalPages: 1,
      pagesToShow: 4,
      blogsPerPage: 6,
    };
  },
  methods: {
    async fetchData() {
      try {
        const [blogResponse, categoryResponse] = await Promise.all([
          this.getForumPost(),
          this.getBlogPost(),
        ]);
        if (blogResponse.data) {
          this.originalBlogs = blogResponse.data.data;
          this.Blogs = this.originalBlogs;
          this.totalPages = Math.ceil(this.Blogs.length / this.blogsPerPage);
        }
        if (categoryResponse.data && categoryResponse.data.data) {
          this.BlogsPost = categoryResponse.data.data;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    getForumPost() {
      return axios.get(`${process.env.VUE_APP_API}/blog/blog-list/`);
    },
    getBlogPost() {
      return axios.get(`${process.env.VUE_APP_API}/blog/category-list/`);
    },
    changePage(newPage) {
      if (newPage === "prev" && this.currentPage > 1) {
        this.currentPage--;
      } else if (newPage === "next" && this.currentPage < this.totalPages) {
        this.currentPage++;
      } else if (
        typeof newPage === "number" &&
        newPage >= 1 &&
        newPage <= this.totalPages
      ) {
        this.currentPage = newPage;
      }
    },
    handleCategoryChange(category) {
      this.selectedCategory = category;
      this.currentPage = 1;
      this.fetchDataForCategory();
    },
    async fetchDataForCategory() {
      try {
        if (this.selectedCategory) {
          const response = await axios.get(
            `${process.env.VUE_APP_API}/blog/blog-list/`,
            {
              params: { category__title: this.selectedCategory },
            }
          );
          this.Blogs = response.data.data;
        } else {
          this.Blogs = this.originalBlogs; // Reset to original data when selecting "All Categories"
        }
        this.totalPages = Math.ceil(this.Blogs.length / this.blogsPerPage);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    formatDate(dateString) {
      const options = { month: "short", year: "numeric" };
      const formattedDate = new Date(dateString).toLocaleDateString(
        "en-IN",
        options
      );
      // Extract day with appropriate suffix (e.g., 1st, 2nd, 3rd, 4th)
      const dayWithSuffix = this.getDayWithSuffix(
        new Date(dateString).getDate()
      );
      // Combine the formatted date and day with suffix
      return `${dayWithSuffix} ${formattedDate}`;
    },
    getDayWithSuffix(day) {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    },
  },
  computed: {
    paginatedBlogs() {
      const startIndex = (this.currentPage - 1) * this.blogsPerPage;
      const endIndex = startIndex + this.blogsPerPage;
      return this.Blogs.slice(startIndex, endIndex);
    },
    chunkedBlogs() {
      // Chunk the blogs into rows with three items each
      const chunkSize = 4;
      return this.paginatedBlogs.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    },
    displayedPages() {
      const startPage = Math.max(
        1,
        this.currentPage - Math.floor(this.pagesToShow / 2)
      );
      const endPage = Math.min(
        this.totalPages,
        startPage + this.pagesToShow - 1
      );
      return Array.from(
        { length: endPage - startPage + 1 },
        (_, i) => startPage + i
      );
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
<style scoped>
.active-category {
  color: #0cb1ef;
}
.category-option {
  display: flex;
  align-items: center;
  border: 1px solid;
  margin-bottom: 5px;
  cursor: pointer;
}
.active-page {
  color: #0cb1ef;
}
</style>
