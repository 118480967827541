<template>
  <section>
    <main class="md:py-20 py-5 px-5 md:px-0">
      <!-- Most Popular Faq -->
      <div class="pt-4 py-5 lg:py-4">
        <h1
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize py-4"
        >
          FAQs
        </h1>
        <div
          class="w-full md:[500px] lg:w-[1000px] mx-auto flex flex-col gap-2 py-[10px] md:py-6"
        >
          <div
            v-for="(item, index) in accordinItem.slice(
                  this.start,
                  this.end,
                )"
            :key="index"
            class="flex flex-col text-left px-[15px] py-[11px] md:px-[25px] md:py-[20px] gap-2 bg-white rounded-lg shadow-sm"
          >
            <div
              class="flex justify-between items-start cursor-pointer text-[#001c54]"
              @click="toggleAccordin(index)"
            >
              <h3
                class="text-[16px] md:text-md lg:text-[20px] text-[#001C54] Gilroy-SemiBold"
              >
                {{ item.title }}
              </h3>
              <div>
                <span
                  v-if="this.activeAccordin !== index"
                  class="pt-[3px] pl-[4px]"
                >
                  <font-awesome-icon icon="fa-angle-down" class="" />
                </span>
                <span v-else class="pt-[3px] pl-[4px]">
                  <font-awesome-icon icon="fa-angle-up" class="" />
                </span>
              </div>
            </div>

            <p
              class="text-[14px] lg:text-[16px] w-full md:w-[500px] lg:w-[657px] text-[#1f1f1f]"
              v-show="activeAccordin === index"
              v-html="item.content"
            ></p>
          </div>
        </div>
        <!-- <div
          v-if="this.end < this.accordinItem.length"
          class="w-full md:flex md:justify-center md:items-center py-2"
        >
          <button
            @click="viewMore"
            class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
          >
            Load More!
          </button>
        </div> -->
        <!-- <div v-else>
          <button
            v-if="this.end > 3"
            @click="viewLess"
            class="w-[140px] bg-[#f8c300] border rounded-xl md:rounded-lg py-[6px] md:py-[8px] font-semibold"
          >
            Load Less!
          </button>
        </div> -->
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: "FaqView",
  data() {
    return {
      faqToShow: 6,
      accordinItem: [
        {
          id: 1,
          title: "What's the benefit of the ZERO cost EMI option?",
          content:
            "A Zero Cost EMI option includes zero processing fee, zero interest, and zero initial payment. You can avail a zero-cost EMI payment option as you enroll in our online degree programs. ",
        },
        {
          id: 2,
          title:
            "Why are our online degree programs a better option than regular, correspondence, part-time, open, or distance learning programs?",
          content:
            "Pursuing an online degree from Sharda University Noida, is regarded as one of the best choices an aspiring learner can make. These are some of the reasons why: <br> 1. Flexible learning schedules make it easy to learn while you earn <br>2. Affordable fee when compared to a regular program <br>3. Our Online degree is equivalent to an on-campus one",
        },
        {
          id: 3,
          title:
            "Is this an Open and Distance Learning (ODL) or Correspondence Program?",
          content:
            "Sharda Online Degree is a 100% online learning mode program.",
        },
        {
          id: 4,
          title: "Will the recording of live sessions be available?",
          content:
            "Yes, all the Sharda Online courses/sessions will be recorded and available on the LMS.",
        },
        {
          id: 5,
          title: "Will study material be provided?",
          content:
            "Yes, the learner is provided with access to an exhaustive and customized E-Learning Material (ELM) on the Learning Management System (LMS).",
        },
        {
          id: 6,
          title:
            "Is the Degree acquired through an online mode equivalent to an on-campus mode of learning?",
          content:
            "Yes, the below notification UGC endorses this.<br>UGC Notification No. F. 1-1/2020(DEB-I) dated 4th September 2020 – Part VI, Page 79, Point. 22 - Equivalence of qualification acquired through Conventional or Online Learning: <br>Degrees at undergraduate and postgraduate level in conformity with UGC notification on Specification of Degrees, 2014 and postgraduate diplomas awarded through Online mode by Higher Educational Institutions, recognized by the Commission under these regulations, shall be treated as equivalent to the corresponding awards of the Degrees at undergraduate and postgraduate level and postgraduate diplomas offered through conventional mode. <br>Our Online Degree programs are recognized by UGC and is equivalent to an on-campus degree.",
        },
      ],
      activeAccordin: null,
      error: null,
      start: 0,
      end: 6,
    };
  },
  methods: {
    toggleAccordin(index) {
      this.activeAccordin = this.activeAccordin === index ? null : index;
    },
    viewMore() {
      this.end += 2;
    },
    viewLess() {
      this.end = 4;
    },
  },
  computed: {
    showMore() {
      return this.faqToShow < this.accordinItem.length;
    },
    showLess() {
      return this.faqToShow > 4 && !this.showMore;
    },
  },
};
</script>

<style></style>
