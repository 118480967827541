<template>
  <div class="bg-black">
    <div class="w-full max-w-7xl mx-auto">
      <div
        class="flex flex-col lg:flex-row justify-center items-center lg:justify-between p-3 gap-5"
      >
        <a href="tel:+917400707567">
          <div class="flex gap-2 items-center">
            <img
              src="@/assets/lp-program/Iconmaterial-call.svg"
              alt=""
              srcset=""
              class="w-[20px] h-[20px]"
            />
            <p class="text-white text-sm md:text-lg">Call us :+91 7400707567</p>
          </div>
        </a>
        <!-- <div class="flex gap-2 items-center">
          <img
            src="@/assets/lp-program/icon-message.svg"
            alt=""
            srcset=""
            class="w-[20px] h-[20px] text-white"
          />
          <a href="mailto:zeba@learningshala.in"><p class="text-white text-sm md:text-lg text-right">
            zeba@learningshala.in
          </p></a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style></style>
