<template>
  <div>
    <!-- header start-->
    <section class="w-full h-full">
      <div class="bg-[#ffff] py-4 lg:py-4 px-5 border-b-2 border-gray-400">
        <div class="w-full max-w-7xl mx-auto">
          
            <div class="hidden md:flex justify-between items-center">
              <img
                src="@/assets/lp-program/sharda-Online-Logo.png"
                alt="Sharda Online"
                srcset=""
                class="md:h-[40px] lg:h-[50px]"
              />
              <div class="flex gap-2 items-center"><a href="tel:+917400707567"><p
                class="text-[#123177] py-3 text-[20px] rounded-3xl flex gap-2 items-center"
              >
                <img
                  src="@/assets/lp-program/phonecall.png"
                  alt="call-icon"
                  class="w-[30px] h-[30px]"
                />
                +91 7400707567
              </p></a>
              <!-- <a href="mailto:zeba@learningshala.in"><p
                class="text-[#123177] py-3 text-[20px] rounded-3xl flex gap-2 items-center"
              >
                <img
                  src="@/assets/lp-program/envalope.png"
                  alt="call-icon"
                  class="w-[40px] h-[40px]"
                />
                zeba@learningshala.in
              </p></a> -->
            </div>
            </div>
          <div class="flex justify-center items-center md:hidden">
            <img
              src="@/assets/lp-program/sharda-Online-Logo.png"
              alt=""
              srcset=""
              class="w-[180px] h-[50px]"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- header end-->

    <section class="lg:border-b-2 lg:border-gray-400">
      <div class="bg-white  lg:h-[560px]">
        <!-- Desktop Banner -->

        <!-- desktop banner image -->
        <div class="h-[560px] relative hidden lg:block">
          <img
            src="@/assets/lp-program/General.webp"
            alt=""
            srcset=""
            class="w-full h-full"
          />
        </div>
        <div class="w-full mx-auto hidden lg:block absolute top-[100px] px-5 ">
          <div class="2xl:w-[1380px] max-w-7xl mx-auto">
            <div class="lg:flex lg:justify-between items-center h-full">
              <!-- content -->
              <div class="flex flex-col gap-8">
                <div class="">
                  <h1
                    class="lg:text-[30px] xl:text-[35px] text-[#17479e] text-[24px] leading-tight md:text-left font-bold"
                  >
                    Online Degree Program
                  </h1>
                </div>
                <div class="text-left bg-[#17479e] w-max p-2 text-white">
                  <span class="pr-2 border-r-2">MBA</span
                  ><span class="pr-2 pl-2 border-r-2">MCA</span
                  ><span class="pr-2 pl-2 border-r-2">M.COM</span
                  ><span class="pr-2 border-r-2 pl-2">BBA</span
                  ><span class="pr-2 border-r-2 pl-2">BCA</span
                  >
                  <!-- <span class="pr-2 border-r-2 pl-2">B.COM</span
                  > -->
                  <span class="pr-2 pl-2">BA(Hons.)</span>
                </div>
                <ul class="text-black flex flex-col gap-1 px-5 md:px-0">
                  <li v-for="list in lists" :key="list">
                    <div class="flex gap-2 items-center">
                      <img :src="list.image" alt="" srcset="" class="w-5 h-5" />
                      <p class="font-bold text-lg">{{ list.content }}</p>
                    </div>
                  </li>
                </ul>

                <h1 class="text-3xl text-black font-bold text-left">
                  Addmission Open 2024
                </h1>
                <p class="font-semibold text-black text-md text-left">
                  Batch Starts - July 2024
                </p>
              </div>

              <!-- desktop banner image
            <div class="h-[560px]">
              <img
                src="@/assets/lp-program/General.webp"
                alt=""
                srcset=""
                class="w-full h-full"
              />
            </div> -->

              <!--Enquire form -->
              <div
                class="pt-5 lg:flex justify-center items-center"
              >
                <div class="border py-4 bg-white shadow-lg w-[330px] 2xl:w-[300px]">
                  <LpEnquireForm />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Banner -->
        <div class="w-full lg:hidden">
          <div class="flex flex-col gap-3 py-4 px-5">
            <!-- content -->
            <h1 class="text-[25px] font-bold text-black">
              Online Degree Program
            </h1>

            <!-- <div class="mt-3 flex justify-center bg-[#123177] rounded-md">
              <ul class="text-white" v-for="key in degrees" :key="key">
                <p class="text-[10px] p-2">{{ key }}</p>
              </ul>
            </div> -->
            <div
              class="text-left text-[10px] bg-[#17479e] w-full flex justify-center p-2 text-white"
            >
              <span class="pr-2 border-r-2">MBA</span
              ><span class="pr-2 pl-2 border-r-2">MCA</span
              ><span class="pr-2 pl-2 border-r-2">M.COM</span
              ><span class="pr-2 border-r-2 pl-2">BBA</span
              ><span class="pr-2 border-r-2 pl-2">BCA</span
              >
              <!-- <span class="pr-2 border-r-2 pl-2">B.COM</span
              > -->
              <span class="pr-2 pl-2">BA(Hons.)</span>
            </div>

            <div class="">
              <img
                src="@/assets/lp-program/1.webp"
                alt=""
                srcset=""
                class="w-[230px] h-full mx-auto"
              />
            </div>
            <h1 class="text-black font-bold">UGC Entitled</h1>
            <p class="text-black font-bold">0 Cost EMI Option</p>
          </div>
          <!--Enquire form -->
          <div class="w-full mt-5 py-4 bg-[#123177]">
            <div class="flex flex-col justify-center items-center py-2">
              <h1 class="text-lg text-white font-bold text-left">
                Addmission Open 2024
              </h1>
              <p class="text-sm text-left text-white">
                Batch Starts - July 2024
              </p>
            </div>
            <div class="w-[320px] md:w-[500px] mx-auto">
              <div class="border py-4 bg-white shadow-lg">
                <LpEnquireForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LpEnquireForm from "@/components/enqireforms/LpEnquireForm.vue";

export default {
  name: "BannerView",
  components: {
    LpEnquireForm,
  },
  data() {
    return {
      lists: [
        {
          id: 1,
          image: require("@/assets/lp-program/play.png"),
          content: "UGC Entitled",
        },
        {
          id: 2,
          image: require("@/assets/lp-program/play.png"),
          content: "0 Cost EMI Option",
        },
      ],

      degrees: ["MBA |", "MCA |", "M.COM |", "BBA |", "BCA |", "B.Com |", "BA"],
    };
  },
};
</script>

<style></style>
