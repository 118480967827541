<template>
  <div>
    <NavBar />

    <!-- banner -->
    <BannerView :elective="Electives" />

    <AboutView :elective="Electives" />
    <!--About the elective end-->

    <HighlightView :elective="Electives" />
    <!--Program highlights end-->

    <!--About the advanced certification-->
    <div class="w-full py-5 bg-[#f7f9fb]">
      <!-- AdvancedCertificate start -->
      <AdvancedCertificate :elective="Electives" />
      <!-- AdvancedCertificate end -->

      <!-- learning Methodology start -->
      <LearningView />
      <!-- learning Methodology end -->

      <!--Learning Methodology end-->

      <!--How to Enroll-->
      <EnrollView />
      <!--How to Enroll end-->

      <!-- career start -->
      <CareerView />
      <!-- career end -->

      <!-- <TechNology /> -->
      <OurTechnology />

      <!-- <div class="w-full py-5  bg-[#f7f9fb]">
        <div class="max-w-7xl mx-auto w-full">
          <h1
            class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left"
          >
            HIRING COMPANIES
          </h1>
          
        </div>
      </div> -->
      <HiringComp />
      <div class="w-full px-8">
        <!-- FAQ -->
        <div id="faq-content" class="">
          <div class="max-w-screen-2xl mx-auto w-full">
            <div class="flex max-w-7xl mx-auto">
              <h1
                class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-left"
              >
                FAQs
              </h1>
            </div>
            <div class="accordion bg-trasparent max-w-6xl mx-auto" id="faqs">
              <div
                v-for="(el, index) in this.ElecFaq.slice(this.start, this.end)"
                :key="el"
                class="accordion-item border-t-0 border-x-0 text-left"
              >
                <h2 class="accordion-header mb-0">
                  <button
                    @click="faqCollapse"
                    class="accordion-button font-semibold relative flex items-center justify-between w-full py-4 px-5 text-[15px] text-[#18469D] hover:text-[#0a58ca] text-left border-0 rounded-none transition focus:outline-none"
                    type="button"
                    :data-target="'#text-' + index"
                  >
                    {{ el.title }}
                    <font-awesome-icon
                      :id="'text-' + index + '-img'"
                      icon="fa-solid fa-circle-chevron-down"
                      alt="down-arrow"
                      class="down-arrow text-[#18469d] text-xl"
                    />
                    <!-- <img
                    src="../assets/home/Untitled.png"
                    alt="down-arrow"
                    class="down-arrow"
                    />  -->
                  </button>
                </h2>
                <div
                  :id="'text-' + index"
                  class="accordion-collapse border-0 hidden text-sm md:text-base text-black"
                >
                  <div class="accordion-body pb-4 px-10 flex flex-col gap-3">
                    <!-- <p v-html="el.answer_text"></p> -->
                    <pre
                      style="white-space: pre-line"
                      class="text-[14px]"
                      v-html="el.content"
                    ></pre>
                  </div>
                </div>
              </div>
            </div>
            <!-- <button class="font-medium">Load more >></button> -->
            <button
              v-if="this.ElecFaq.length >= this.end"
              @click="this.end = this.end + 3"
              class="bg-[#0CB1EF] text-white font-semibold px-8 py-3 rounded-[50px] mt-7"
            >
              View More
            </button>
            <button
              v-if="this.ElecFaq.length <= this.end"
              @click="this.end = 4"
              class="bg-[#0CB1EF] text-white font-semibold px-8 py-3 rounded-[50px] mt-5"
            >
              View Less
            </button>
          </div>
        </div>
      </div>
    </div>
    <FooterView />
    <StickyView />
    <div class="Bannermodal1" v-if="isModalOpen">
      <div class="Bannermodal-content1 rounded-md">
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import axios from "axios";
// import syllaBus from "@/components/SyllaBus.vue";
// import CAS from "@/components/CAServices.vue";
// import TechNology from "@/components/TechNology.vue";
import HiringComp from "@/components/HiringComp.vue";
import FooterView from "@/components/FooterView.vue";
import NavBar from "@/components/NavBar.vue";
import StickyView from "@/components/StickyView.vue";
import EnquireView from "@/components/enqireforms/EnquireForm.vue";
import EnrollView from "../components/ElectiveDetail/EnrollView.vue";
import AboutView from "../components/ElectiveDetail/AboutView.vue";
import HighlightView from "../components/ElectiveDetail/HighlightView.vue";
import LearningView from "../components/ElectiveDetail/LearningView.vue";
import OurTechnology from "../components/ElectiveDetail/OurTechnology.vue";
import AdvancedCertificate from "../components/ElectiveDetail/AdvancedCertificate.vue";
import CareerView from "../components/ElectiveDetail/CareerView.vue";
import BannerView from "../components/ElectiveDetail/BannerView.vue";
export default {
  name: "MbaView",
  components: {
    // syllaBus,
    // CAS,
    // TechNology,
    BannerView,
    OurTechnology,
    FooterView,
    HiringComp,
    EnrollView,
    AboutView,
    HighlightView,
    LearningView,
    AdvancedCertificate,
    NavBar,
    StickyView,
    EnquireView,
    CareerView,
  },
  data() {
    return {
      selectedTab: 0,
      Electives: [],
      start: 0,
      end: 4,
      ElecFaq: [],
      isModalOpen: false,
    };
  },

  //   props: ["elective"],
  props: ["short_slug", "slug"],

  created() {
    this.electiveCall();
    this.electiveFaq();
  },

  methods: {
    faqCollapse(e) {
      var accordionItem = $(e.target).parents(".accordion-item");
      var dataTarget = accordionItem
        .find(".accordion-button")
        .attr("data-target");

      var icon = accordionItem.find(".accordion-button .down-arrow");

      if (icon.hasClass("rotate-180")) {
        icon.removeClass("rotate-180");
        $(dataTarget).hide("slow");
      } else {
        $(".accordion-item .accordion-button .down-arrow").removeClass(
          "rotate-180"
        );
        $(".accordion-item .accordion-collapse").hide("slow");
        icon.addClass("rotate-180");
        $(dataTarget).show("slow");
      }
    },

    async electiveCall() {
      const slug = this.$route.params.slug; // Get the slug from the route
      const apiUrl = `${process.env.VUE_APP_API}/course/elective-list/?program__short_slug=${this.short_slug}&&slug=${slug}`;

      try {
        const resp = await axios.get(apiUrl);
        if (resp.data.status === 200) {
          this.Electives = resp.data.data[0];
        } else {
          this.$router.push({
            name: "PageNotFound",
            params: { pathMatch: "page-not-found" },
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

    async electiveFaq() {
      const electiveSlug = this.$route.params.slug; // Get the slug from the route or define it
      const apiUrl = `${process.env.VUE_APP_API}/faq/elective-faq-list/?elective__slug=${electiveSlug}`;

      if (!electiveSlug) {
        // Handle the case when the slug is not available
        console.error("elective Slug is not defined.");
        return;
      }

      try {
        const response = await axios.get(apiUrl);

        if (response.data) {
          this.ElecFaq = response.data.data;
        }
      } catch (error) {
        console.error(error); // Handle any errors that may occur during the request
      }
    },

    selectTab(tabId) {
      this.selectedTab = tabId;
    },

    openModel() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
@media (max-width: 425px) {
  .gradbox {
    width: 180px;
  }
}
@media (max-width: 375px) {
  .gradbox {
    width: 158px;
  }
}
@media (max-width: 320px) {
  .gradbox {
    width: 133px;
  }
}
.grad {
  background-color: #364c70;
  background-image: linear-gradient(to right, #0cb1ef, #bed630);
  /* padding: 45px 30px; */
}

ul li::before {
  content: "\2022";
  color: #18469d;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 1em;
  margin-left: 1em;
}

.highlights li {
  padding-left: 20px;
  background: url(@/assets/Program/icon-bulb.svg) left 3px no-repeat;
  background-size: auto;
  background-size: 12px;
  margin-bottom: 8px;
  list-style-type: none !important;
}

@media (min-width: 768px) {
  .Arrow::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #18469d;
    bottom: -10px;
    left: 50%;
    transform: rotate(45deg);
  }
}
.active-tab {
  background-color: #0cb1ef; /* Active tab style */
  color: white;
}

.active-content {
  display: block; /* Show active content */
}

.Bannermodal1 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.Bannermodal-content1 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  bottom: 7%;
}

@media (max-width: 767.98px) {
  .Bannermodal-content1 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

h2 {
  font-size: 18px !important;
}

.close:hover {
  color: black;
}
</style>
