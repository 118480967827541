<template>
  <div>
    <div class="w-full mx-auto relative object-cover bg-center bg-no-repeat">
      <div class="md:px-8">
        <div class="max-w-7xl mx-auto">
          <div
            class="absolute md:top-1 top-24 text-[10px] bg-gray-50 px-2 py-1 md:p-0 md:bg-transparent md:text-xs"
          >
            <p
              class="text-[#0CB1EF] md:text-black font-bold text-sm text-left"
              v-if="this.elective.program"
            >
              <a href="/" class="text-[#0CB1EF]">Home</a> >
              <a :href="`/${elective.program.slug}`" class="text-[#0CB1EF]">{{
                elective.program.name
              }}</a>
              >
              <span class="text-black"> {{ elective.name }}</span>
            </p>
          </div>
        </div>
      </div>
      <img :src="elective.banner" class="hidden md:block w-full mt-24" />
      <img :src="elective.mobile_banner" class="md:hidden w-full pt-20" />
      <div class="px-8">
        <div class="max-w-7xl mx-auto">
          <div
            class="absolute top-[35%] md:top-[12%] lg:top-[18%] 2xl:top-[10%] text-white"
          >
            <h1
              class="text-[24px] md:text-3xl lg:text-4xl bg-[#18479E] bg-opa px-2 mb-2 font-bold w-[16rem] md:w-[18rem] text-start text-white whitespace-nowrap"
            >
              {{ elective.sub_name }}
            </h1>
            <h1
              class="text-[24px] md:text-3xl lg:text-4xl bg-[#18479E] bg-opa px-2 font-bold w-[18rem] md:w-max text-start text-white"
            >
              {{ elective.name }}
            </h1>
          </div>
          <div>
            <div
              class="flex flex-col md:flex-row gap-4 absolute top-[58%] md:top-[40%] xl:top-[40%] 2xl:top-[27%]"
            >
              <div class="flex">
                <span
                  ><img
                    src="@/assets/programInfo/naac.webp"
                    alt="naac"
                    class="w-[40px]"
                /></span>
                <span
                  class="text-sm font-bold bannernaac text-white position-relative top-[10px] md:text-black"
                  >NAAC A+ ACCREDITED</span
                >
              </div>
              <div class="flex">
                <span
                  ><img
                    src="@/assets/programInfo/ugc.webp"
                    alt="ugcweb"
                    class="w-[40px]" /></span
                ><span
                  class="text-sm text-start bannernaac font-bold text-white md:text-black"
                  >UGC ENTITLED</span
                >
              </div>
            </div>
          </div>
          <div>
            <div
              class="absolute text-xl w-full leading-7 text-left font-[700] top-[76%] md:top-[55%] xl:top-[50%] 2xl:top-[40%] flex flex-col gap-5"
            >
              <div class="flex flex-wrap gap-10">
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Duration.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Duration</h1>
                    <p class="text-[#18479E] text-xs">2 YEARS</p>
                  </div>
                </div>
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Program-Credits.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Program Credits</h1>
                    <p class="text-[#18479E] text-xs">92</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap gap-6">
                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Flexible.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Flexible</h1>
                    <p class="text-[#18479E] text-xs">EMI OPTION</p>
                  </div>
                </div>

                <div class="flex gap-2 items-center">
                  <div>
                    <img
                      src="@/assets/elective/Live-Online-Sessions.png"
                      alt=""
                      class="w-7 h-7"
                    />
                  </div>
                  <div class="flex flex-col">
                    <h1 class="text-xs text-black">Live Online Sessions</h1>
                    <p class="text-[#18479E] text-xs">WEEKEND</p>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="text-black md:text-left absolute bottom-[3%] md:bottom-[-2%] lg:bottom-[1%] xl:bottom-[20%] 2xl:bottom-[36%]"
            >
              <div class="flex lg:flex-col flex-row gap-5">
                <a href="https://apply.shardaonline.ac.in/" target="_blank">
                  <button
                    class="bg-[#0CB1EF] text-white font-[600] text-[16px] px-4 py-2 w-[150px] md:py-2 md:w-36 mt-5 rounded-2xl"
                  >
                    APPLY NOW
                  </button>
                </a>
              </div>
            </div>
            <div
              class="text-black md:text-left absolute bottom-[3%] md:bottom-[-2%] lg:bottom-[1%] xl:bottom-[20%] 2xl:bottom-[36%] left-[45%] md:left-[25%] lg:left-[20%] xl:left-[18%] 2xl:left-[25%] xxl:left-[32%]"
              v-if="this.elective.brochures"
            >
              <div class="flex lg:flex-col flex-row gap-5">
                <button
                  class="bg-[#0CB1EF] text-white font-[600] text-[16px] px-4 py-2 md:py-2 w-[200px] mt-5 rounded-2xl"
                  @click="openModalDownload"
                >
                  Download Brochure
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- <div class="Bannermodal1" v-if="isModalOpen">
      <div class="Bannermodal-content1 rounded-md">
        <span class="close" @click="closeModal">&times;</span>
        <EnquireView />
      </div>
    </div> -->
    <!-- Modal -->
    <!--Downaload brouche-->
    <div class="Bannermodal2" v-if="isModalDownloadOpen">
      <div
        class="Bannermodal-content2 md:w-[50%] lg:w-[40%] xl:w-[30%] xxl:w-[20%] rounded-md"
      >
        <span class="close" @click="closeModalDownload">&times;</span>
        <DownloadEnquireElectiveFormVue :electiveInfo="this.elective" />
      </div>
    </div>
    <!--Downaload brouche-->
  </div>
</template>

<script>
// import EnquireView from "@/components/enqireforms/EnquireForm.vue";
import DownloadEnquireElectiveFormVue from "../enqireforms/DownloadEnquireElectiveForm.vue";
export default {
  name: "BannerView",
  props: ["elective"],
  components: {
    // EnquireView,
    DownloadEnquireElectiveFormVue,
  },
  data() {
    return {
      isModalOpen: false,
      isModalDownloadOpen: false,
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    openModalDownload() {
      this.isModalDownloadOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    closeModalDownload() {
      this.isModalDownloadOpen = false;
    },
  },
  updated() {},
};
</script>

<style>
.Bannermodal1 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.Bannermodal2 {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.bullets li {
  list-style-image: url("https://ik.imagekit.io/usdcglobalweb/sharda/Star.webp?updatedAt=1714671608122");
}

.bannernaac {
  position: relative;
  top: 10px;
  text-indent: 8px;
}

.bg-opa {
  opacity: 0.8;
  /* text-transform: uppercase; */
}

.bullets li > span {
  position: relative;
  top: -8px;
}

.Bannermodal-content1 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
  bottom: 7%;
}
.Bannermodal-content2 {
  position: relative;
  background-color: white;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
}

@media (max-width: 767.98px) {
  .Bannermodal-content1 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
  .Bannermodal-content2 {
    position: relative;
    background-color: white;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 95%;
    top: 20%;
  }
}

.close {
  position: relative;
  color: #aaa;
  float: right;
  top: -25px;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover {
  color: black;
}
</style>
