<template>
  <div class="w-full px-5 py-6 lg:py-10">
    <div class="fixed top-0 left-0 h-full w-full bg-gray-100 -z-10"></div>
    <div class="max-w-7xl mx-auto w-full px-3">
      <div class="flex flex-col gap-2 md:gap-3 text-center justify-center">
        <h2
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize"
        >
          Meet Our Faculty
        </h2>
      </div>
      <!-- faculty slider desktop -->
      <div class="hidden md:block slick_faculty md:pt-5">
        <div
          @click="
            openmodal(card.details, card.name);
            openCard(card.id);
          "
          v-for="card in cards"
          :key="card"
        >
          <div class="">
            <div class="h-[300px] relative flex justify-center">
              <div
                class="absolute bottom-0 w-[250px] h-[150px] flex flex-col justify-end bg-white shadow-lg rounded-xl px-3 py-5"
              >
                <h2 class="text-[17px] font-semibold text-black">
                  {{ card.name }}
                </h2>
                <p class="text-[14px]">{{ card.study }}</p>
                <p class="text-[14px]">{{ card.job }}</p>
              </div>
              <div
                class="absolute top-[20px] z-10 w-[200px] h-[200px] flex justify-center rounded-xl"
              >
                <img
                  :src="card.imgDesh"
                  class="w-full h-full object-cover shadow-lg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- faculty slider mobile -->
      <div class="md:hidden">
        <div class="slick_faculty_mob pt-5">
          <div
            @click="
              openmodal(card.details, card.name);
              openCard(card.id);
            "
            v-for="card in cards"
            :key="card"
          >
            <div class="w-full h-[250px] relative flex justify-center">
              <div
                class="w-full h-[160px] absolute bottom-0 flex flex-col justify-end bg-white shadow-lg rounded-xl px-3 pb-3"
              >
                <h2 class="text-[15px] font-semibold text-black">
                  {{ card.name }}
                </h2>
                <p class="text-[14px]">{{ card.study }}</p>
                <p class="text-[14px]">{{ card.job }}</p>
              </div>
              <div
                class="absolute top-[30px] z-10 w-[180px] h-[130px] flex justify-center rounded-xl"
              >
                <img
                  :src="card.imgMob"
                  class="w-full h-full object-cover mx-auto"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center items-center gap-3 pt-3">
          <div
            class="prev cursor-pointer w-10 h-10 rounded-md bg-white flex justify-center items-center"
          >
            <img
              src="@/assets/home/faculty/arrow-left.svg"
              alt=""
              class="md:w-6 lg:w-8 text-black"
            />
          </div>
          <div
            class="next cursor-pointer w-10 h-10 flex justify-center items-center rounded-md bg-white"
          >
            <img
              src="@/assets/home/faculty/arrow-right.svg"
              alt=""
              class="md:w-6 lg:w-8"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="modal" id="myModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <div v-for="card in cards" :key="card.id">
          <div
            class="text-sm text-[#2c3e50] text-left p-2"
            v-if="cardId == card.id"
          >
            <span class="w-[150px] pb-4 flex justify-left rounded-xl">
              <img
                :src="card.imgDesh"
                class="w-full h-full object-cover"
                alt=""
              />
            </span>
            <span class="font-semibold md:text-xl text-md text-left"
              >{{ card.name }} <br
            /></span>
            <span class="font-semibold text-[#18469D] text-xs"
              >{{ card.study }} <br /></span
            >{{ card.details }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
export default {
  name: "FacultyView",
  data() {
    return {
      faculties: "",
      cardId: null,
      cards: [
        {
          id: 1,
          imgDesh: require("@/assets/home/faculty/raju-ganesh-sundar.webp"),
          imgMob: require("@/assets/home/faculty/raju-ganesh-sundar.webp"),
          name: "Prof. (Dr.) Raju Sunder",
          study: "Professor",
          details:
            "Dr. Raju Sunder has received a Doctorate in Human Resource Management, is a Management Graduate from RTM Nagpur University, Nagpur, and an Engineering Graduate from the prestigious Visvesvaraya National Institute of Technology, Nagpur. He has more than 35+ years of experience in leadership levels in both industry and academia.",
        },
        {
          id: 2,
          imgDesh: require("@/assets/home/faculty/Shubh-Arora.webp"),
          imgMob: require("@/assets/home/faculty/Shubh-Arora.webp"),
          name: "Dr. Shubh Arora",
          study: "Associate Professor",
          details:
            "Dr. Shubh Arora has over 14 years of rich academic and research experience. Holding a Ph.D. in Marketing and an MBA in Marketing & Finance, she began her journey in academia after graduating from HNB Garhwal University. Driven by a passion for continuous improvement, she has undertaken a Refresher Course on Quality Research & Teaching Methodology from UGC-HRDC, Jodhpur. She is committed to fostering dynamic learning environments and employs innovative teaching pedagogy and strategies to enhance student engagement and comprehension. She cultivates an environment where students thrive and excel by integrating cutting-edge methodologies tailored to individual learning styles. Her expertise is evident in her extensive publication record, comprising over 10 papers in prestigious national and international journals indexed with SCOPUS, ABDC, UGC, and other databases. Her research interests span across services marketing, consumer behavior, retail management, digital marketing, and marketing management.",
        },
        {
          id: 3,
          imgDesh: require("@/assets/home/faculty/Dr-Tanya-Rastogi.webp"),
          imgMob: require("@/assets/home/faculty/Dr-Tanya-Rastogi.webp"),
          name: "Dr. Tanya Rastogi",
          study: "Assistant Professor",
          details:
            "Dr. Tanya Rastogi is an academic and researcher having teaching experience of more than 6 years. With a Ph.D. from Amity University, Noida, focusing on the influence of sustainable marketing practices on customer loyalty and purchase intention, she also holds a Master of Commerce and a Bachelor's degree from Delhi University. She has accumulated significant teaching experience as a faculty at the University of Delhi and Amity University. She has numerous papers to her name published in prestigious journals, including those indexed by SCOPUS, Web of Science, and ABDC. She is a keen researcher with her research featured in “A category” journals such as the Journal of Cleaner Production. She has also presented papers in national and international conferences and attended many national and international seminars and workshops. She is dedicated to creating dynamic and engaging learning environments, employing innovative teaching methodologies to enhance student engagement and comprehension. Her research interests include sustainability, marketing, consumer behaviour, and brand loyalty. ",
        },
        {
          id: 4,
          imgDesh: require("@/assets/home/faculty/Dr-Sandeep-Kumar.webp"),
          imgMob: require("@/assets/home/faculty/Dr-Sandeep-Kumar.webp"),
          name: "Dr. Sandeep Kumar",
          study: "Assistant Professor",
          details:
            "Dr. Sandeep Kumar is working as an Assistant Professor (Management) at Centre for Distance and Online Education, Sharda University, Greater Noida. He completed his B. Sc. (Life Science) in 2008 from MDU, Rohtak, Haryana, India. He completed MBA in 2010 from TMV, Pune, Maharashtra, India, M. Phil in 2015 from MMU, Ambala and M, Sc. (Psychology) in 2019 from MKU, Tamil Nadu. He completed his Ph.D. in Marketing in 2022 from MMU, Ambala, Haryana, India. He is in teaching profession for more than 9 years. He has presented 4 papers in National and International Journals, Conference and Symposiums. His main areas of interest includes Psychology, Human Behavior and Brand Management.",
        },
        {
          id: 5,
          imgDesh: require("@/assets/home/faculty/Kirt-prashar.webp"),
          imgMob: require("@/assets/home/faculty/Kirt-prashar.webp"),
          name: "Ms. Kirti Prashar",
          study: "Assistant Professor",
          details:
            "Ms. Kirti Prashar specializes in commerce and marketing, possessing a wealth of knowledge backed by a Master's in Commerce, UGC NET certification, and ongoing pursuit of a Ph.D. in Marketing; she brings academic excellence to her role. With more than five years of dedicated teaching and research experience, she has contributed significantly to academia through numerous publications, including books, book chapters, case studies, and research papers in esteemed national and international journals. Her expertise extends beyond publication, serving as an editor and reviewer for reputable academic journals and with a good number of patents published under her name. She epitomizes dedication, expertise, and passion in her pursuit of advancing knowledge and nurturing future generations of scholars and learners. ",
        },
        {
          id: 6,
          imgDesh: require("@/assets/home/faculty/Madhu-Verma.webp"),
          imgMob: require("@/assets/home/faculty/Madhu-Verma.webp"),
          name: "Ms. Madhu Verma",
          study: "Assistant Professor",
          details:
            "Ms. Madhu Verma brings a wealth of expertise in commerce and research to her role. With a Master's degree in Commerce and qualification in the National Eligibility Test (NET), she possesses a solid academic foundation and good teaching experience. Ms. Verma's dedication to research and data analysis is evident through her active engagement in related courses. Her effective verbal communication skills and critical thinking abilities enhance her teaching and research endeavors, making her a valuable asset to both her students and the academic community.",
        },
        {
          id: 7,
          imgDesh: require("@/assets/home/faculty/Dr-Falguni-Singh.webp"),
          imgMob: require("@/assets/home/faculty/Dr-Falguni-Singh.webp"),
          name: "Dr. Falguni Singh",
          study: "Assistant Professor",
          details:
            "Dr. Falguni Singh is presently working as an Assistant Professor in Centre for Distance and Online Education at Sharda University, Greater Noida. She did her B. Com. from Lucknow University, Lucknow, UP, India. She did her M.Com. from Lucknow University, Lucknow, UP, India. She also did her M.A. in Economics from CSJM University, Kanpur, UP, India. She is also NET qualified in Commerce field. She completed her Ph.D. in Commerce from Lucknow University, Lucknow, UP, India. She is in teaching profession for more than 5 years. She has presented 5 papers in National and International Journals, Conference and Symposiums. Her main areas of interest are Financial Accounting, Economics, Financial Management and Statistics.",
        },
        {
          id: 8,
          imgDesh: require("@/assets/home/faculty/Vandana.webp"),
          imgMob: require("@/assets/home/faculty/Vandana.webp"),
          name: "Ms. Vandana",
          study: "Assistant Professor",
          details:
            "Ms. Vandana is working as an Assistant Professor, M.com, Centre for Distance and Online Education. She is an approved faculty in CCS University. She holds a Masters` degree in Commerce as well as Management. She has qualified UGC NET in commerce and management as well. She also possesses an enriching experience in the teaching industry. She is genuinely enthusiastic towards contributing to  the academic community through teaching and research endeavours. She has many certifications for attending workshops and FDPs.",
        },
        {
          id: 9,
          imgDesh: require("@/assets/home/faculty/Aditya-Tandon.webp"),
          imgMob: require("@/assets/home/faculty/Aditya-Tandon.webp"),
          name: "Mr. Aditya Tandon",
          study: "Assistant Professor",
          details:
            "Aditya Tandon has graduated with a B.Tech. CSE from the Apex Institute of Engineering & Technology, Jaipur, Rajasthan, India. He secured a Master of Technology in Computer Science and Engineering at Amity University, Noida, Uttar Pradesh, India. He is pursuing a Ph.D. in Computer Science Engineering at Quantum University, Roorkee, Uttarakhand, India. He has been in the teaching profession for more than 10 years. He has presented 25 papers in National and International Journals, conferences, and symposiums. His main areas of interest include the Internet of Things and Data Science.",
        },
        {
          id: 10,
          imgDesh: require("@/assets/home/faculty/Priyanka-Joshi.webp"),
          imgMob: require("@/assets/home/faculty/Priyanka-Joshi.webp"),
          name: "Ms. Priyanka Joshi",
          study: "Assistant Professor",
          details:
            "Ms. Priyanka Joshi has more than 11 years of teaching experience. She earned her B.Sc. in Computer Science from Kumaun University, Nainital, in 2006 and her MCA from Uttarakhand Technical University, Dehradun, in 2009. She is pursuing a Ph.D. from Bennett University, Greater Noida. She has taken various certification courses at Google, IBM, and Microsoft. Her fields of interest are Machine learning, Data Science, C, C++, Python, and Database management systems.",
        },
        {
          id: 11,
          imgDesh: require("@/assets/home/faculty/Kamala-Kumari.webp"),
          imgMob: require("@/assets/home/faculty/Kamala-Kumari.webp"),
          name: "Dr. Kamala Kumari",
          study: "Assistant Professor",
          details:
            "Dr. Kamala Kumari is working as an Assistant Professor, B.A. (Hons.) Political Science, Centre for Distance and Online Education. She has taught as an Assistant Professor at the University of Delhi. New Delhi, India. She has a doctorate degree from the School of International Studies, Jawaharlal Nehru University, New Delhi, India. She has her Bachelor's and Master's Degrees from the School of Languages, Literature and Culture Studies, Jawaharlal Nehru University, Delhi, India. She has immense knowledge of the Russian language. She has presented papers at International and national conferences and has participated in roundtables on issues related to Security, Gender, and IR in India, Russia, and Central Asia. She has publications that include chapters in edited books and a number of articles in reputed journals.",
        },
        {
          id: 12,
          imgDesh: require("@/assets/home/faculty/Shama-Akthar.webp"),
          imgMob: require("@/assets/home/faculty/Shama-Akthar.webp"),
          name: "Dr. Shama Akhtar",
          study: "Assistant Professor",
          details:
            "Dr. Shama Akhtar has over three years of academic experience. She has completed her M.A. (Gold Medalist) and Ph.D. (Public Administration) from Aligarh Muslim University (AMU) Aligarh. She has published books, book chapters, and research papers in well-reputed national and international journals and conferences. She is a standing member of the organizing team in ICSSR-sponsored workshops as well as a member of the Cultural Committee, Publication Committee, and Workshops Seminar Committee of her previous institute.",
        },
        {
          id: 13,
          imgDesh: require("@/assets/home/faculty/Dr Premendra Kumar Singh.webp"),
          imgMob: require("@/assets/home/faculty/Dr Premendra Kumar Singh.webp"),
          name: "Dr. Premendra Kumar Singh",
          study: "Assistant Professor",
          details:
            "Dr. Premendra Kumar Singh, with over 8 years of experience in industry and academia, is an Engineering and Management graduate from Mizoram University. He was awarded his Doctorate from Mizoram University. He has attended and presented papers in over 15 national and international seminars/conferences, and he has published research papers in various national and international peer- reviewed journals of international repute. He has edited one book and has also contributed book chapters in 3 edited books. He takes special interest in Corporate Social Responsibility and Marketing for research.",
        },
        {
          id: 14,
          imgDesh: require("@/assets/home/faculty/Ms Shreyi Mittal copy.webp"),
          imgMob: require("@/assets/home/faculty/Ms Shreyi Mittal copy.webp"),
          name: "Ms. Shreyi Mittal ",
          study: "Assistant Professor",
          details:
            "She is a dedicated professional, research oriented with over 4+ years of experience, with history of meeting targeted and set goals by utilizing consistent and organized practices. Skilled in working under pressure to adapting to new situations, she is proficient in managing, organizing meetings and events, handling confidential documents, and communicating with internal and external resources. She possesses exceptional communication and interpersonal skills with a proven ability to work independently and as part of a team. She has contributed both in national and international conferences, area of research being Information Retrieval, also is exploring the fields of ML & DL, and cyber analytics as well.",
        },
      ],
    };
  },
  mounted() {
    $(`.slick_faculty`).slick({
      infinite: true,
      autoplay: true,
      prevArrow: false,
      nextArrow: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      dots: true,
      dotsClass: "slick-dotsFaculty",

      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1026,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 950,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $(`.slick_faculty_mob`).slick({
      infinite: true,
      autoplay: true,
      prevArrow: $(".prev"),
      nextArrow: $(".next"),
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
    });
  },

  methods: {
    openmodal(cards) {
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.faculties = cards;
    },

    closeModal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "none";
      this.faculties = "";
    },
    openCard(id) {
      this.cardId = id;
    },
  },
};
</script>

<style>
.slick-dotsFaculty {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-dotsFaculty li {
  margin: 0 0.25rem;
}
.slick-dotsFaculty button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  margin: 5px;
  border: none;
  border-radius: 100%;
  background-color: silver;
  text-indent: -9999px;
}
.slick-dotsFaculty li.slick-active button {
  background-color: #18469d;
  width: 0.75rem;
  height: 0.75rem;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px 20px 10px 20px;
  border: 1px solid #888;
  width: 50%;
  height: 90%;
  overflow: scroll;
}

@media (max-width: 767.98px) {
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 10px 10px 50px 10px;
    border: 1px solid #888;
    width: 100%;
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.slick_praise {
  width: 100%;
  margin: 20px auto;
  text-align: center;
}

.slick_praise div {
  margin-right: 5px;
}

.slick-praise-dot {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-praise-dot li {
  margin: 0 0.25rem;
}
.slick-praise-dot button {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  padding: 0;
  border: none;
  border-radius: 15px;
  background-color: silver;
  text-indent: -9999px;
}
.slick-praise-dot li.slick-active button {
  background-color: #f8c300;
  width: 2.5rem;
  height: 0.5rem;
}
</style>
