<template>
  <div class="home">
    <NavBar />
    <section>
      <BannerView />
    </section>

    <section>
      <DegreeProgram />
    </section>
    <!-- ranking & Recognitions -->
    <section>
      <div
        class="w-full mx-auto justify-center items-center max-w-7xl pt-10 lg:px-10"
      >
        <div>
          <h2
            class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize"
          >
            Rankings & Recognitions
          </h2>
        </div>
        <div class="w-full relative">
          <div
            class="next-ranking absolute bottom-0 right-24 md:right-[300px] lg:top-[40%] lg:-right-10 z-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-next.svg"
              class=""
              width="40px"
              height="40px"
              alt="slider-next"
            />
          </div>
          <div
            class="prev-ranking absolute bottom-0 left-24 md:left-[300px] lg:top-[40%] lg:-left-10 z-10 cursor-pointer"
          >
            <img
              src="../assets/icon-slider-prev.svg"
              width="40px"
              height="40px"
              class=""
              alt="slider prev"
            />
          </div>
          <div
            class="slick-ranking flex justify-center max-w-7xl mx-auto items-center gap-10"
          >
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] lg:w-[300px] xl:w-[380px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p>
                    Accredited by National Assessment and Accreditation Council
                    (NAAC) with an A+ Grade
                  </p>
                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg mb-8"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr1.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] lg:w-[300px] xl:w-[380px] lg:h-[180px] h-[220px] flex flex-col justify-center items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    Ranked amongst the top 87th in India under National
                    Institutional Ranking Framework (NIRF), Ministry of
                    Education, Government of India
                  </p>
                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr2.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] lg:w-[300px] xl:w-[380px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-16">
                    University Grants Commission (UGC) Entitled Online Degree
                    Programs of Sharda online are equivalent to on-campus mode
                    of learning
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr3.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] lg:w-[300px] xl:w-[380px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p class="pt-12">
                    Sharda's Foundation for Science, Technology, and Research is
                    Awarded ISO 9001:2015 certification
                  </p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr4.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="relative h-[320px]">
              <div class="flex h-full items-center justify-center m-auto">
                <div
                  class="border border-slate-200 rounded-lg w-[305px] lg:w-[300px] xl:w-[380px] lg:h-[180px] h-[220px] flex flex-col justify-end items-center py-6 px-4 bg-white"
                >
                  <p>India Today 2021 - Best Universities in India Rank: 87</p>

                  <div
                    class="rounded-full p-2 bg-white absolute lg:top-4 top-0 left-[35%] shadow-lg"
                  >
                    <img
                      class="h-[90px] w-[90px] rounded-full"
                      src="../assets/home/ranking/rr5.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Skills that enhance your learning -->
    <!-- <section class="w-full p-10 bg-[#f7f9fb]">
      <SkillsView />
    </section> -->

    <!-- RedefineView start -->
    <section>
      <!-- <RedefineView /> -->
      <WhyUsView />
    </section>
    <!-- RedefineView end -->

    <!-- Meet our Faculty start -->
    <FacultyView />

    <OurSupportStaff />
    <!-- Meet our Faculty end -->

    <!-- testimonial start -->
    <!-- <TestimonialsView /> -->
    <!-- testimonial end -->

    <!-- news and media start -->
    <!--<NewsMedia />-->
    <!-- news and media end -->

    <section>
      <h2
        class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize"
      >
        Top Hiring Companies
      </h2>

      <HiringView />
    </section>

    <DreamsView />
    <section class="px-8"> 
      <div class="w-full mx-auto max-w-7xl">
      <h2
        class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center capitalize"
      >
        Experience Sharda University
      </h2>
      <iframe src="https://www.sharda.ac.in/vtour/tour.html?startscene=0&amp;startactions=lookat(5.99,0.08,120,0,0);" width="100%" height="500" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      </div>
    </section>
    <section>
      <FaQ />
    </section>

    <section>
      <FooterView />
    </section>

    <StickyView />
  </div>
</template>

<script>
import $ from "jquery";
import "slick-carousel";
import BannerView from "@/components/HomeBanner.vue";
import DegreeProgram from "@/components/DegreeProgram.vue";
import FaQ from "@/components/FaQ.vue";
import HiringView from "@/components/home/HiringView.vue";
import DreamsView from "@/components/DreamsView.vue";
import FooterView from "@/components/FooterView.vue";
import StickyView from "@/components/StickyView.vue";
import NavBar from "@/components/NavBar.vue";
// import SkillsView from '@/components/home/SkillsView.vue'
import WhyUsView from "@/components/ElectiveDetail/WhyUsView.vue";
// import RedefineView from "../components/home/RedefineView.vue";
import FacultyView from "../components/home/FacultyView.vue";
import OurSupportStaff from "../components/home/OurSupportStaff.vue";

// import TestimonialsView from "../components/home/TestimonialsView.vue";
// import NewsMedia from "@/components/home/NewsMedia.vue";
export default {
  name: "HomeView",
  components: {
    BannerView,
    DegreeProgram,
    // SkillsView,
    WhyUsView,
    // RedefineView,
    FacultyView,
    OurSupportStaff,
    // TestimonialsView,
    // NewsMedia,
    HiringView,
    DreamsView,
    FooterView,
    FaQ,
    StickyView,
    NavBar,
  },

  data() {
    return {
      selectedVideo: "",
      selectedVideoName: "",
      accordionUpdate1: [],
      accordionUpdate2: [],

      accordionItems1: [
        {
          image: require("@/assets/home/skills/time-management.svg"),
          title: "Problem-Solving and Critical Thinking Skills",
          alt: "Problem-Solving",

          open: false,
          subItems: [
            {
              title: "Problem-solving",
              open: false,
            },
            {
              title: "Critical thinking",
              open: false,
            },
            {
              title: "Decision making",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Leadership.svg"),
          title: "Leadership and Management Skills",
          alt: "Leadership and Management",

          open: false,
          subItems: [
            {
              title: "Leadership",
              open: false,
            },
            {
              title: "Strategic thinking",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/emotional-intelligence.svg"),
          title: "Emotional Intelligence and Personal Skills",
          alt: "Emotional Intelligence",

          open: false,
          subItems: [
            {
              title: "Emotional Intelligence",
              open: false,
            },
            {
              title: "Empathy",
              open: false,
            },
            {
              title: "Positive attitude",
              open: false,
            },
            {
              title: "Self-management",
              open: false,
            },
            {
              title: "Resilience",
              open: false,
            },
            {
              title: "Adaptability",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/communication.svg"),
          title: " Communication and Interpersonal Skills",
          alt: "Communication",

          open: false,
          subItems: [
            {
              title: "Communication",
              open: false,
            },
            {
              title: "Interpersonal communication",
              open: false,
            },
            {
              title: "Active listening",
              open: false,
            },
            {
              title: "Public speaking",
              open: false,
            },
          ],
        },
      ],
      accordionItems2: [
        {
          image: require("@/assets/home/skills/Creativity.svg"),
          title: "Creativity and Innovation Skills",
          alt: "Creativity ",

          open: false,
          subItems: [
            {
              title: "Creativity",
              open: false,
            },
            {
              title: "Design thinking",
              open: false,
            },
            {
              title: "Curiosity",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Computer-literacy.svg"),
          title: "Technical and Computer Skills",
          alt: "Technical Skills",

          open: false,
          subItems: [
            {
              title: "Digital literacy",
              open: false,
            },
            {
              title: "Data analysis",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Self-management.svg"),
          title: "Industry-Specific Skills",
          alt: "Industry Specific Skills",

          open: false,
          subItems: [
            {
              title: "Writing",
              open: false,
            },
            {
              title: "Negotiation",
              open: false,
            },
            {
              title: "Personal Branding",
              open: false,
            },
            {
              title: "Work ethic",
              open: false,
            },
            {
              title: "Customer service",
              open: false,
            },
            {
              title: "Professionalism",
              open: false,
            },
          ],
        },
        {
          image: require("@/assets/home/skills/Project Management.svg"),
          title: "Time and Project Management Skills",
          alt: "Time and Project Managment Skills",
          open: false,
          subItems: [
            {
              title: "Project management",
              open: false,
            },
            {
              title: "Time management",
              open: false,
            },
            {
              title: "Teamwork",
              open: false,
            },
          ],
        },
      ],
    };
  },

  mounted() {
    $(".slick-faculty").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-faculty"),
      nextArrow: $(".next-faculty"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".slick-ranking").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-ranking"),
      nextArrow: $(".next-ranking"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".slick-testimonials").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-testimonials"),
      nextArrow: $(".next-testimonials"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".slick-media").slick({
      infinite: true,
      autoplay: true,
      speed: 200,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $(".prev-media"),
      nextArrow: $(".next-media"),
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    this.updatedList();
    window.addEventListener("click", this.outsideClick);
  },

  methods: {
    openmodal(video, name) {
      const modal = document.getElementById("myModal");
      modal.style.display = "block";
      this.selectedVideo = video;
      this.selectedVideoName = name;
    },

    closeModal() {
      const modal = document.getElementById("myModal");
      modal.style.display = "none";
      this.selectedVideo = "";
    },

    toggleAccordion(index) {
      this.accordionItems1.forEach((item, i) => {
        if (i !== index) {
          item.open = false;
        }
      });
      this.accordionItems2.forEach((item) => {
        item.open = false;
      });
      this.accordionItems1[index].open = !this.accordionItems1[index].open;
    },
    toggleSubAccordion(accordionIndex, subAccordionIndex) {
      this.accordionItems1[accordionIndex].subItems[subAccordionIndex].open =
        !this.accordionItems1[accordionIndex].subItems[subAccordionIndex].open;
    },

    toggleAccordion2(index) {
      this.accordionItems2.forEach((item, i) => {
        if (i !== index) {
          item.open = false;
          this.accordionItems1[index].open = false;
        }
      });
      this.accordionItems1.forEach((item) => {
        item.open = false;
      });
      this.accordionItems2[index].open = !this.accordionItems2[index].open;
    },
    toggleSubAccordion2(accordionIndex, subAccordionIndex) {
      this.accordionItems2[accordionIndex].subItems[subAccordionIndex].open =
        !this.accordionItems2[accordionIndex].subItems[subAccordionIndex].open;
    },

    // showAllItems() {
    //   this.accordionUpdate1 = this.accordionItems1;
    //   this.accordionUpdate2 = this.accordionItems2;
    // },

    updatedList() {
      if (this.accordionItems1.length > 0) {
        this.accordionUpdate1 = this.accordionItems1.slice(0, 4);
      }

      if (this.accordionItems2.length > 0) {
        this.accordionUpdate2 = this.accordionItems2.slice(0, 4);
      }
    },
  },

  watch: {
    showUpdated() {
      this.updatedList();
    },
  },
};
</script>

<style scoped>
/* your modal and overlay styles here */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.3);
}

/* Modal Content */
.modal-content1 {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

@media (max-width: 767.98px) {
  .modal-content1 {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
  }
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
