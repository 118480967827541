<template>
  <div>
    <div class="w-full max-w-7xl mx-auto px-5">
      <h2
        class="text-[24px] lg:text-[30px] text-[#18479e] font-bold text-center uppercase py-4"
      >
        Career Support
      </h2>

      <!-- desktop -->
      <div class="hidden md:block">
        <div class="flex">
          <button
            v-for="career in careers"
            :key="career"
            @click="selectedcareer = career"
            :class="{
              ' border bg-[#034ea2] text-blue-400 ': selectedcareer === career,
              ' border bg-black shadow-2xl  ': selectedcareer !== career,
            }"
            class="text-center py-2 text-[14px] w-full 2xl:text-[14px] text-white font-semibold"
          >
            {{ career }}
          </button>
        </div>
        <div v-if="selectedcareer === 'Industry Mentors'">
          <div v-for="para in paras" :key="para" class="bg-white shadow">
            <p class="text-left p-6">
              {{ para.description }}
            </p>
          </div>
        </div>
        <div v-if="selectedcareer === 'Guidance for Further Education'">
          <div v-for="para in paras1" :key="para" class="bg-white shadow">
            <p class="text-left p-6">
              {{ para.description }}
            </p>
          </div>
        </div>
        <div v-if="selectedcareer === 'Master Classes'">
          <div v-for="para in paras2" :key="para" class="bg-white shadow">
            <p class="text-left p-6">
              {{ para.description }}
            </p>
          </div>
        </div>
        <div v-if="selectedcareer === 'Profile Building'">
          <div v-for="para in paras3" :key="para" class="bg-white shadow">
            <p class="text-left p-6">
              {{ para.description }}
            </p>
          </div>
        </div>
        <div v-if="selectedcareer === 'Placement Assitance'">
          <div v-for="para in paras4" :key="para" class="bg-white shadow">
            <p class="text-left p-6">
              {{ para.description }}
            </p>
          </div>
        </div>
      </div>

      <!-- mobile -->

      <div>
        <section>
          <div class="max-w-7xl w-full mx-auto">
            <div class="md:py-10 py-2 md:hidden block">
              <!-- loop the cards -->
              <div
                class="flex flex-col gap-5 lg:flex-row lg:justify-between p-5 lg:p-0"
              >
                <div v-for="(enquire, index) in career" :key="index">
                  <div
                    class="w-full p-3 border rounded-md shadow-lg  text-left flex flex-col gap-2"
                    :class="index == activeAccordin ? ' bg-[#17479E]' : 'bg-black'"
                  >
                    <div
                      class="flex justify-between items-center cursor-pointer"
                      @click="toggleAccordin(index)"
                    >
                      <h1
                        class="text-[14px] md:text-[16px] lg:text-[16px] text-white font-bold"
                      >
                        {{ enquire.title }}
                      </h1>
                      <div>
                        <span
                          v-if="this.activeAccordin !== index"
                          class="pt-[10px] pr-[4px]"
                          ><font-awesome-icon
                            icon="fa-angle-down"
                            class="text-white"
                          />
                        </span>
                        <span v-else class="pt-[3px] pl-[4px]">
                          <font-awesome-icon
                            icon="fa-angle-up"
                            class="text-white"
                          />
                        </span>
                      </div>
                    </div>

                    <div
                      v-show="activeAccordin === index"
                      class="bg-white h-[400px] flex flex-col gap-2"
                    >
                      <p class="text-sm p-2">
                        {{ enquire.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CareerSupport",
  data() {
    return {
      careers: [
        "Industry Mentors",
        "Guidance for Further Education",
        "Master Classes",
        "Profile Building",
        "Placement Assitance",
      ],
      selectedcareer: "Industry Mentors",
      paras: [
        {
          id: 1,
          title: "Industry Mentors",
          description:
            "Connect with accomplished professionals and gain invaluable insights into your chosen field. Our platform bridges the gap between academics and industry by pairing you with experienced mentors who provide real-world perspectives, career advice, and guidance tailored to your aspirations. Elevate your learning experience as you navigate the intricacies of your industry, build meaningful connections, and receive personalized mentorship to propel your career forward. Embrace the wisdom of seasoned professionals with our Industry mentors and embark on a journey toward success. ",
        },
      ],
      paras1: [
        {
          id: 1,

          description:
            "Explore the endless possibilities of your educational journey with our comprehensive guidance for further education. After Graduation, if you are looking to upskill, our expert advisors are here to steer you toward the right path. From selecting suitable programs to assisting with application processes, we provide personalized support to help you make informed decisions about your academic future. Unlock new horizons and achieve your educational aspirations with our dedicated guidance for further education. ",
        },
      ],
      paras2: [
        {
          id: 1,
          description:
            "Embark on a transformative learning journey with our exclusive Master Classes, where industry experts and experienced professionals share their insights, strategies, and practical wisdom. Dive deep into specialized topics, refine your skills, and gain a competitive edge in your field. Our Master Classes provide a platform for immersive learning and mastery. Stay ahead in your educational and professional pursuits with unparalleled expertise delivered straight to you.",
        },
      ],
      paras3: [
        {
          id: 1,
          description:
            "Discover the art of effective self-presentation with profile-building resources. We guide you through the process of showcasing your skills, achievements, and aspirations. Elevate your online presence and make a lasting impression on recruiters with a polished and impactful profile that reflects your unique strengths. Shape your narrative, stand out, and open doors to new opportunities with our profile-building support. ",
        },
      ],
      paras4: [
        {
          id: 1,
          description:
            "We provide Placement Assistance to empower learners with the tools and support needed to navigate the professional landscape successfully. From resume building to interview preparation, our expert faculty ensures that you are well-equipped to secure rewarding opportunities in your chosen field. Elevate your educational journey with our commitment to fostering your career growth and helping you land the perfect job.",
        },
      ],
      career: [
        {
          id: 1,
          title: "Industry Mentors",
          description:
            "Connect with accomplished professionals and gain invaluable insights into your chosen field. Our platform bridges the gap between academics and industry by pairing you with experienced mentors who provide real-world perspectives, career advice, and guidance tailored to your aspirations. Elevate your learning experience as you navigate the intricacies of your industry, build meaningful connections, and receive personalized mentorship to propel your career forward. Embrace the wisdom of seasoned professionals with our Industry mentors and embark on a journey toward success. ",
        },
        {
          id: 2,
          title: "Guidance for Further Education",
          description:
            "Explore the endless possibilities of your educational journey with our comprehensive guidance for further education. After Graduation, if you are looking to upskill, our expert advisors are here to steer you toward the right path. From selecting suitable programs to assisting with application processes, we provide personalized support to help you make informed decisions about your academic future. Unlock new horizons and achieve your educational aspirations with our dedicated guidance for further education. ",
        },
        {
          id: 3,
          title: "Master Classes",
          description:
            "Embark on a transformative learning journey with our exclusive Master Classes, where industry experts and experienced professionals share their insights, strategies, and practical wisdom. Dive deep into specialized topics, refine your skills, and gain a competitive edge in your field. Our Master Classes provide a platform for immersive learning and mastery. Stay ahead in your educational and professional pursuits with unparalleled expertise delivered straight to you ",
        },
        {
          id: 4,
          title: "Profile Building",
          description:
            "Discover the art of effective self-presentation with profile-building resources. We guide you through the process of showcasing your skills, achievements, and aspirations. Elevate your online presence and make a lasting impression on recruiters with a polished and impactful profile that reflects your unique strengths. Shape your narrative, stand out, and open doors to new opportunities with our profile-building support. ",
        },
        {
          id: 5,
          title: "Placement Assitance",
          description:
            "We provide Placement Assistance to empower learners with the tools and support needed to navigate the professional landscape successfully. From resume building to interview preparation, our expert faculty ensures that you are well-equipped to secure rewarding opportunities in your chosen field. Elevate your educational journey with our commitment to fostering your career growth and helping you land the perfect job. ",
        },
      ],
      activeAccordin: null,
    };
  },
  methods: {
    toggleAccordin(index) {
      if (index === this.activeAccordin) {
        this.activeAccordin = null;
      } else {
        this.activeAccordin = index;
      }
    },
  },
};
</script>

<style></style>
