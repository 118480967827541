<template>
     <NavBar />
<div class="w-full">
    <ol class="flex gap-2 items-center pt-24 pl-3 font-normal text-[#001c54]">
        <li><a href="/">Home</a></li>
        <li>> Privacy Policy</li>
    </ol>
   
    <p class="py-1 pl-4 text-start leading-7 font-normal text-black text-[14px]">We respect the privacy of those who visit our web site. In accordance with this, we have created this policy in order to demonstrate our privacy commitment to our users:</p>
    <div class="w-full text-left pl-5 pb-5">      
        <h4 class="mt-5 text-[18px] font-bold text-black ">What this Privacy Policy Covers</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">This Privacy Policy covers our treatment of personally identifiable information that we collect when you are on our site, and when you use our services. This policy also covers our treatment of any personally identifiable information that third parties share with us.</p>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">This policy does not apply to the practices of organizations that we do not own or control, or to people that we do not employ or manage </p>

        <h4 class="text-[18px] font-bold text-black">Information Collection and Use</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We collect personally identifiable information when you register on our website, when you use our services, and when you visit our pages. We may also receive personally identifiable information from third parties.</p>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">When you register with us, we ask for your name, email address, zip code, occupation, industry, and personal interests. Once you register with us and sign in to our services, you are not anonymous to us.
        </p>
        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We use this information for three general purposes: to customize the content you see, to fulfill your requests for certain services, and to contact you about services.</p>
        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We also automatically receive and record information on our server logs from your browser including your IP address, cookie information and the page you requested. This information is not linked to your account and will not be used to identify you.</p>





        <h4 class="mt-5 text-[18px] font-bold text-black">Information Sharing and Disclosure</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We will not sell or rent your personally identifiable information to anyone.</p>

        <h4 class="mt-5 text-[18px] font-bold text-black">We will send personally identifiable information about you to other companies or people when</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We have your consent to share the information;</p>
        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We respond to subpoenas, court orders or legal process; or</p>
        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">We find that your actions on our web sites violate the Terms of Service</p>


        <h4 class="mt-5 text-[18px] font-bold text-black">Changes to this Privacy Policy</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">The privacy policy is subject to modification from time to time. If we decide to change our privacy policy, we will post those changes here so that you will always know what information we gather, how we might use that information and whether we will disclose it to anyone. Any significant changes to our privacy policy will be announced on our home page. If you do not agree with the changes in our policy you can simply discontinue to visit our website.
        </p>

        <h4 class="mt-5 text-[18px] font-bold text-black">Questions or Suggestions</h4>

        <p class="py-1 text-start leading-7 font-normal text-black text-[14px]">If you have questions or suggestions send an email to us.</p>

                
      
    </div>
</div>
<FooterView />
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterView from "@/components/FooterView.vue";
    export default {
    name: "PrivacyPolicy",   
    components: { 
        FooterView,
        NavBar 
    }
}
</script>

<style scoped>

</style>