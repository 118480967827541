<template>
  <div class="w-full">
    <section class="w-full max-w-7xl mx-auto px-5 pt-[120px]">
      <!-- refund policy start -->
      <div class="text-left flex flex-col gap-3">
        <h2 class="text-[24px] lg:text-[35px] text-[#18479E] font-bold">
          Refund Policy
        </h2>
        <h3 class="text-[18px] lg:text-[24px] text-[#000000] font-bold">
          Admission Cancellation and Refund Rules

        </h3>
        <p class="text-[14px] text-black">
          The fee refund policy and guidelines of the University applicable to online programs is hereby notified.
        </p>
      </div>
      <!-- Components of fees: -->

      <div class="text-left flex flex-col gap-3 py-3 lg:py-6">
        <h3 class="text-[18px] lg:text-[24px] text-[#000000] font-bold">
          Components of fees:
        </h3>
        <div class="flex flex-col gap-3">
          <table class="border-separate border-spacing-3">
            <thead>
              <tr class="w-full text-[14px] lg:text-[18px] text-white">
                <th class="w-1/2 bg-[#0CB1EF] px-5 py-2.5">
                  
                  
                  
                </th>
                <th class="w-1/2 bg-[#0CB1EF] px-5 py-2.5"></th>
              </tr>
            </thead>
            <tbody class="mt-2">
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-1/2 text-left py-8 px-5">Student application is cancelled by the University</td>
                <td class="w-1/2 text-left py-8 px-5">
                  Full refund of Program Fee and Examination Fee & Registration Fee
                </td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-1/2 text-left py-8 px-5">Program has been cancelled by the University</td>
                <td class="w-1/2 text-left py-8 px-5">
                  Full refund of Program Fee, Examination Fee and Registration Fee
                </td>
              </tr>
            </tbody>
        </table>
          <!--<p class="text-[14px] lg:text-[18px] text-black px-3">
            If cancellation of admission and refund of fee request is made by the student:
          </p>-->
        </div>
      </div>
      <!-- Components of fees: -->
      <!--<div class="text-left flex flex-col gap-3 py-3 lg:py-6">
        <h3 class="text-[18px] lg:text-[24px] text-[#000000] font-bold">
          If cancellation of admission and refund of fee request is made by the student:
        </h3>
        <div class="flex flex-col gap-3">
          <table class="border-separate border-spacing-3">
            <thead>
              <tr class="w-full text-[14px] lg:text-[18px] text-white">
                <th class="w-[30%] bg-[#0CB1EF] px-5 py-2.5">Reason</th>
                <th class="w-[30%] bg-[#0CB1EF] px-5 py-2.5">
                  Cancelled
                  <br class="lg:hidden" />
                  by
                </th>
                <th class="w-[30%] bg-[#0CB1EF] px-5 py-2.5">
                  Present Refund Practice
                </th>
              </tr>
            </thead>
            <tbody class="mt-2">
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-[30%] text-left py-8 px-3">
                  Program / Course Fee
                </td>
                <td class="w-[30%] text-left py-8 px-3">University</td>
                <td class="w-[30%] text-left py-8 px-3">
                  Full refund of program/course fee and exam fees.
                </td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-[30%] text-left py-8 px-3">
                  Program has been cancelled
                </td>
                <td class="w-[30%] text-left py-8 px-3">University</td>
                <td class="w-[30%] text-left py-8 px-3">
                  Full refund of program/course fee and exam fees.
                </td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-[30%] text-left py-8 px-3">
                  Student request for change in Specialization / Batch
                </td>
                <td class="w-[30%] text-left py-8 px-3">Student</td>
                <td class="w-[30%] text-left py-8 px-3">
                  No refund, INR 2500/- deducted as processing fee and balance
                  fee adjusted towards the new course fee. As per specific
                  rules.
                </td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-[30%] text-left py-8 px-3">
                  Student chooses to withdraw from the program of study
                </td>
                <td class="w-[30%] text-left py-8 px-3">Student</td>
                <td class="w-[30%] text-left py-8 px-3">
                  As mentioned in the table below***
                </td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-[30%] text-left py-8 px-3">
                  Student detained because of backlogs
                </td>
                <td class="w-[30%] text-left py-8 px-3">University</td>
                <td class="w-[30%] text-left py-8 px-3">
                  Full refund of program/course fee and exam fees.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>-->
      <!-- ***Student chooses to withdraw from the program of study -->
      <div class="text-left flex flex-col gap-3 py-3 lg:py-6">
        <h3 class="text-[18px] lg:text-[24px] text-[#000000] font-bold">
          If cancellation of admission and refund of fee request is made by the student: 
        </h3>
        <div class="flex flex-col gap-3">
          <table class="border-separate border-spacing-3">
            <thead>
              <tr class="w-full text-[14px] lg:text-[18px] text-white">
                <th class="w-1/2 bg-[#0CB1EF] px-5 py-2.5">Time of Request</th>
                <th class="w-1/2 bg-[#0CB1EF] px-5 py-2.5">Amount of Refund</th>
              </tr>
            </thead>
            <tbody class="mt-2">
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-1/2 text-left py-8 px-5">
                  15 days or more prior to the commencement of classes (as notified in Academic Calendar)
                </td>
                <td class="w-1/2 text-left py-8 px-5">
                  100% of fee paid (Processing Fee of Rs. 1000 will be deducted as processing fee)
                </td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-1/2 text-left py-8 px-5">
                  Less than 15 days before the commencement of classes (as notified in Academic Calendar)
                </td>
                <td class="w-1/2 text-left py-8 px-5">90% of fee paid</td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-1/2 text-left py-8 px-5">
                  15 days or less after the commencement of classes (as notified in Academic Calendar)
                </td>
                <td class="w-1/2 text-left py-8 px-5">
                  80% of fee paid
                </td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-1/2 text-left py-8 px-5">
                  More than 15 days but less than 30 days after the commencement of classes (as notified in Academic Calendar)
                </td>
                <td class="w-1/2 text-left py-8 px-5">50% of fee paid</td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="w-1/2 text-left py-8 px-5">
                  More than 30 days after the commencement of classes (as notified in Academic Calendar)
                </td>
                <td class="w-1/2 text-left py-8 px-5">NIL</td>
              </tr>
            </tbody>
          </table>
          <!--<p class="text-[12px] lg:text-[14px] text-black px-3">
            Refund requests shall be processed within 30 working days post the
            confirmation of academics team.
          </p>
          <p class="text-[12px] lg:text-[14px] text-black px-3">
            NOTE: For any refund-related enquiries, you may contact the Email ID
            learnersupport@shardaonline.com
          </p>-->
        </div>
      </div>
    </section>
    <div>
      <FooterView />
    </div>
  </div>
</template>

<script>
import FooterView from "@/components/FooterView.vue";
export default {
  name: "RefundScope",
  components: {
    FooterView,
  },
};
</script>

<style></style>
