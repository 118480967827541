<template>
  <div>
    <!-- header start-->
    <section class="w-full h-full">
      <div class="bg-[#ffff] py-4 lg:py-4 px-5 border-b-2 border-gray-400">
        <div class="w-full max-w-7xl mx-auto">
          <a :href="`tel:+91${program.phone_number}`">
            <div class="hidden md:flex justify-between items-center">
              <img
                src="@/assets/lp-program/sharda-Online-Logo.png"
                alt="Sharda Online"
                srcset=""
                class="md:h-[40px] lg:h-[50px]"
              />
              <p
                class="text-[#123177] py-3 text-[20px] rounded-3xl flex gap-2 items-center"
              >
                <img
                  src="@/assets/lp-program/phonecall.png"
                  alt="call-icon"
                  class="w-[30px] h-[30px]"
                />
                +91 {{ this.program.phone_number }}
              </p>
            </div>
          </a>
          <div class="flex justify-center items-center md:hidden">
            <img
              src="@/assets/lp-program/sharda-Online-Logo.png"
              alt=""
              srcset=""
              class="w-[180px] h-[50px]"
            />
          </div>
        </div>
      </div>
    </section>
    <!-- header end-->

    <section class="lg:border-b-2 lg:border-gray-400">
      <div class="bg-white lg:h-[560px] md:px-4 2xl:p-0">
        <!-- Desktop Banner -->
        <div
          class="w-full max-w-7xl lg:h-[560px] mx-auto hidden md:block relative"
        >
          <div class="lg:flex lg:justify-between items-center h-full px-5">
            <!-- content -->
            <div class="flex flex-col gap-8 w-full">
              <div class="flex flex-col gap-4">
                <h1
                  class="lg:text-[30px] xl:text-[35px] text-[#17479e] text-[24px] leading-tight md:text-left font-bold"
                >
                  {{ this.program.title }}
                </h1>
                <p
                  class="text-left text-[18px] bg-[#17479e] w-[390px] p-2 text-white"
                >
                  {{ program.program_name }}
                </p>
              </div>

              <ul class="text-black flex flex-col gap-1 px-5 md:px-0">
                <li v-for="list in program.banner_list" :key="list">
                  <div class="flex gap-2 items-center">
                    <img
                      src="@/assets/lp-program/play.png"
                      alt=""
                      srcset=""
                      class="w-5 h-5"
                    />
                    <p class="font-bold text-lg">{{ list }}</p>
                  </div>
                </li>
              </ul>

              <!-- <div class="flex gap-4">
                <div class="flex items-center">
                  <span
                    ><img
                      src="@/assets/lp-program/Untitled.webp"
                      alt="ugcweb"
                      class="w-[60px] h-[60px]" /></span
                  ><span
                    class="text-sm font-bold w-[5rem] md:text-black text-left"
                    >NAAC A+ Accredited</span
                  >
                </div>
                <div class="flex items-center">
                  <span
                    ><img
                      src="@/assets/programInfo/ugc.webp"
                      alt="ugcweb"
                      class="w-[60px] h-[60px]" /></span
                  ><span
                    class="text-sm font-bold w-[5rem] md:text-black text-left"
                    >UGC ENTITLED</span
                  >
                </div>
              </div> -->

              <h1 class="text-3xl text-black font-bold text-left">
                Addmission Open 2024
              </h1>
              <p class="text-blacl text-lg text-left">
                Batch Starts - July 2024
              </p>
            </div>

            <!-- desktop banner image -->
            <div class="hidden lg:block absolute bottom-0 left-[37%]">
              <img
                :src="program.banner"
                alt=""
                srcset=""
                class="xl:w-[385px] md:h-[250px] lg:h-[455px] xl:h-[505px] object-contain"
              />
            </div>
            <!-- desktop wedget -->
            <div class="py-[50px] lg:py-[150px] px-5 lg:px-0">
              <div
                class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
              >
                <strong>Thank you</strong> for your inquiry. Someone from our
                team <br />
                will be in touch with you shortly.
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Banner -->
        <div class="w-full lg:hidden">
          <div class="flex flex-col gap-3 py-4 px-5">
            <!-- content -->
            <div class="flex flex-col gap-4">
              <h1 class="text-[25px] font-bold text-black">
                {{ this.program.title }}
              </h1>
              <p
                class="text-left text-[14px] bg-[#17479e] w-full flex justify-center p-2 text-white"
              >
                {{ program.program_name }}
              </p>
            </div>
            <!-- <div class="mt-3 flex justify-center bg-[#17479e] rounded-md">
              <ul class="text-white" v-for="key in degrees" :key="key">
                <p class="text-[12px] p-2">{{ key }}</p>
              </ul>
            </div> -->
            <!-- <div
              class="text-left text-[10px] bg-[#17479e] w-full flex justify-center p-2 text-white"
            >
              <span class="pr-2 border-r-2">MBA</span
              ><span class="pr-2 pl-2 border-r-2">MCA</span
              ><span class="pr-2 pl-2 border-r-2">M.COM</span
              ><span class="pr-2 border-r-2 pl-2">BBA</span
              ><span class="pr-2 border-r-2 pl-2">BCA</span
              ><span class="pr-2 border-r-2 pl-2">B.COM</span
              ><span class="pr-2 pl-2">BA(Hons.)</span>
            </div> -->

            <div>
              <img
                :src="program.mobile_banner"
                alt=""
                srcset=""
                class="w-[180px] h-full mx-auto"
              />
            </div>
            <h1 class="text-black font-bold">UGC Entitled</h1>
            <p class="text-black font-bold">0 Cost EMI Option</p>
          </div>

          <!-- thankyou widget-->

          <div class="py-[50px] lg:py-[150px] px-5 lg:px-0">
            <div
              class="lg:w-[350px] h-[182px] bg-[#cfd5e4] p-[40px] font-semibold text-[18px] lg:text-[20px]"
            >
              <strong>Thank you</strong> for your inquiry. Someone from our team
              <br />
              will be in touch with you shortly.
            </div>
          </div>
          <!-- thankyou widget -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>


export default {
  name: "BannerView",
  props: ["program"],
  components: {
    
  },
  data() {
    return {
      degrees: ["MBA", "MCA", "M.COM", "BBA", "BCA", "B.Com", "BA"],
    };
  },
};
</script>

<style></style>
