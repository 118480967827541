<template>
  <NavBar />
    
    <div class="mx-auto max-w-6xl h-full">
    <section class="w-full max-w-7xl mx-auto px-5 pt-[120px]">
      <!-- Disclosure of Information -->
      <div class="text-left flex flex-col gap-3">
        <h2 class="text-[24px] lg:text-[35px] text-[#18479E] font-bold px-3">
          Mandatory Disclosure
        </h2>
        <!-- <h3 class="text-[18px] lg:text-[24px] text-[#000000] font-bold">
          Admission Cancellation and Refund Rules
        </h3>
        <p class="text-[14px] text-black">
          The fee refund policy and guidelines of the University applicable to online programs is hereby notified.
        </p> -->
      </div>

      <div class="text-left flex flex-col gap-3 py-3 lg:py-6">
        <h3 class="text-[12px] lg:text-[15px] text-[#000000] font-bold px-3" >
          Compliance status of Regulations UGC (ODL and Online Programs) Regulations - Self-regulation through disclosures, declarations and reports
        </h3>
        <div class="flex flex-col gap-3 bg-[#F6F8FB]">
          <table class="border-separate border-spacing-3">
            <thead>
              <tr class="w-full text-[14px] lg:text-[18px] text-white">
                <th class="bg-[#0CB1EF] px-5 py-2.5">S.No.</th>
                <th class="bg-[#0CB1EF] px-5 py-2.5">Information</th>
                <th class="bg-[#0CB1EF] px-5 py-2.5">Details</th>
              </tr>
            </thead>
            <tbody class="mt-2">
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">1.</td>
                <td class="w-1/2 text-left py-2 px-5">Establishing Act and Statutes</td>
                <td class="w-1/2 text-left py-2 px-5">U.P. Private Universities Act, 2019 - <a href="https://api.shardaonline.ac.in/media/U-P-Private-Universities-Act-2019_86047.pdf"
                  target="_blank" class="text-[#18479E]">Click Here</a><br>
Statutes of Sharda University - <a href="https://api.shardaonline.ac.in/media/Statutes-of-Sharda-University_14848.pdf"
                  target="_blank" class="text-[#18479E]">Click Here</a><br>
Ordinances of the University - <a href="https://api.shardaonline.ac.in/media/Ordinances-of-the-University_14910.pdf"
                  target="_blank" class="text-[#18479E]">Click Here</a></td>
                
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">2.</td>
                <td class="w-1/2 text-left py-2 px-5">Applicaton Submitted to UGC-DEB for offering ODL/Online Programs</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.ac.in/media/UGC-DEB-for-offering-ODL-Online-Programmes_93593.pdf"
                  target="_blank">Click Here</a></td>
                
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">3.</td>
                <td class="w-1/2 text-left py-2 px-5">Equivalence of Online Mode Degree</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.ac.in/media/Equivalence-Online-Mode.pdf"
                  target="_blank">Click Here</a></td>
                
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">4.</td>
                <td class="w-1/2 text-left py-2 px-5">UGC-DEB Public Notice</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.ac.in/media/UGC-DEB-Public-Notice.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">  
                <td class="text-left py-2 px-5">5.</td>
                <td class="w-1/2 text-left py-2 px-5">UGC-DEB Approval</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.ac.in/media/DEB_Aug_2023_Approval.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">6.</td>
                <td class="w-1/2 text-left py-2 px-5">Copies of Letter of Recognition from Commission</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.ac.in/media/DEB_Aug_2023_Approval.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">7.</td>
                <td class="w-1/2 text-left py-2 px-5">Formation of Committee of CIQA for Centre for Distance and Online Education (CDOE) of Sharda University</td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.ac.in/media/SU_Reg_Notification_2024_021_Formation_of_Committee_for_CIQA_of_CDOE_dt_14-05-24_24370.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">8.</td>
                <td class="w-1/2 text-left py-2 px-5">Approval Document of Compliance of Mandatory Disclosure </td>
                <td class="w-1/2 text-left py-2 px-5 text-[#18479E]"><a href="https://api.shardaonline.ac.in/media/Compliance-of-Mandatory-Disclosure_39810.pdf"
                  target="_blank">Click Here</a></td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">9.</td>
                <td class="w-1/2 text-left py-2 px-5">E-Samadhan</td>
                <td class="w-1/2 text-left py-2 px-5">For e-Samadhan<br>Mr. Vivek Kumar, Nodal Officer,<br> <a class="text-[#18479E]" href="mailto:registrar@sharda.ac.in"
                  target="_blank">registrar@sharda.ac.in</a>, <br>AISHE id: 0541</td>
              </tr>
              <tr class="w-full text-[12px] lg:text-[14px] text-black bg-white">
                <td class="text-left py-2 px-5">10.</td>
                <td class="w-1/2 text-left py-2 px-5">Feedback Mechanism</td>
                <td class="w-1/2 text-left py-2 px-5">Email us at<br> <a class="text-[#18479E]" href="mailto:info@shardaonline.ac.in"
                  target="_blank">info@shardaonline.ac.in</a></td>
              </tr>
             
            </tbody>
        </table>
          
        </div>
      </div>
      
      
        
      
    </section>
  </div>
  <div>
      <FooterView />
    </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import FooterView from "@/components/FooterView.vue";

export default {
  name: "DisclosureView",
  components: {
    NavBar,
    FooterView,
  },
};
</script>

<style></style>
