<template>
  <div class="w-full py-3 px-8 md:py-6">
    <section class="w-full max-w-7xl md:mx-auto">
      <!-- <div class="flex flex-col gap-3 text-left">
        <h1
          class="text-[24px] lg:text-[30px] text-[#18479e] font-bold capitalize"
        >
          About The Advanced Certification
        </h1>
        <p class="text-[14px]">
          There is an opportunity to earn an advance certification while you
          pursue this program
        </p>
      </div> -->
      <!-- courses tabs start -->
      <!-- <div class="pt-3 lg:pt-5">
        <div class="flex gap-2 lg:gap-4">
          <div
            v-for="program in programsList"
            :key="program"
            class="flex flex-wrap gap-2 lg:gap-5"
          >
            <button
              @click="activeProgram = program"
              :class="{
                'border-[#0CB1EF] text-[#0CB1EF] text-[14px] lg:text-[18px]':
                  activeProgram === program,
                '': activeProgram !== program,
              }"
              v-html="program"
              class="w-[155px] lg:w-[300px] px-4 py-[13px] text-left lg:text-center text-[14px] lg:text-[18px] font-medium shadow border rounded-lg"
            ></button>
          </div>
        </div>
        <div class="py-3 lg:py-6">
          <div v-if="activeProgram === 'Project Management'">
            <div v-for="programs in programsproject" :key="programs.id">
              <div
                class="flex flex-col gap-3 text-left bg-white p-5 lg:px-8 lg:py-6"
              >
                <p
                  class="text-[14px] lg:text-[16px]"
                  v-html="programs.description"
                ></p>
                <h2 class="font-bold text-[18px] text-black">
                  The Advanced Certificate Program in {{ programs.title }} will
                  take you through an insightful journey in the following
                  courses:
                </h2>
                <p class="w-[90%] lg:w-[30%] text-[14px] lg:text-[16px]">
                  {{ programs.courses }}
                </p>
              </div>
            </div>
          </div>
          <div v-if="activeProgram === 'Data Science and Analytics'">
            <div v-for="programs in programsData" :key="programs.id">
              <div
                class="flex flex-col gap-3 text-left bg-white p-5 lg:px-8 lg:py-6"
              >
                <p
                  class="text-[14px] lg:text-[16px]"
                  v-html="programs.description"
                ></p>
                <h2 class="font-bold text-[18px] text-black">
                  The Advanced Certificate Program in {{ programs.title }} will
                  take you through an insightful journey in the following
                  courses:
                </h2>
                <p class="w-[90%] lg:w-[30%] text-[14px] lg:text-[16px]">
                  {{ programs.courses }}
                </p>
              </div>
            </div>
          </div>
          <p
            class="text-[12px] lg:text-[14px] text-left py-3 lg:py-5 px-5 lg:px-10"
          >
            Note: For the duration of your program you can only choose and study
            one certificate course from the given list
          </p>
        </div>
      </div> -->
      <!-- courses tabs end -->
      <!-- Syllabus,Structure,Eligibility start -->
      <div class="py-5">
        <!-- <div class="pt-3 lg:pt-5"> -->
        <div class="flex gap-2 lg:gap-4">
          <div
            v-for="program in courseDetails"
            :key="program"
            class="flex flex-wrap gap-2 lg:gap-5"
          >
            <button
              @click="activeCourse = program"
              :class="{
                'border-[#0CB1EF] text-[#0CB1EF] text-[14px] lg:text-[18px]':
                  activeCourse === program,
                '': activeCourse !== program,
              }"
              v-html="program"
              class="px-4 lg:px-4 py-[8px] text-left lg:text-center text-[14px] lg:text-[18px] font-medium shadow border rounded-lg"
            ></button>
          </div>
        </div>
        <div class="py-6 lg:py-8">
          <!-- Syllabus start -->
          <div v-if="activeCourse === 'Syllabus'" class="hidden lg:block">
            <div class="flex justify-start gap-5 flex-wrap" v-if="elective">
              <!-- 1st Semester -->
              <div
                class="flex flex-col gap-3 p-4 bg-white courseShadow text-left w-full lg:w-[280px] px-6"
                v-for="el in elective.syllabus"
                :key="el.id"
              >
                <h2 class="text-[18px] text-[#18479E] font-bold">
                  {{ el.semester }}
                </h2>
                <div class="text-[14px] flex flex-col gap-1">
                  <span class="p-2 elctive" v-html="el.content"></span>
                </div>
              </div>
              <!--  Semester -->
            </div>
          </div>
          <div v-if="activeCourse === 'Syllabus'" class="lg:hidden">
            <div
              class="w-full flex flex-col gap-2 py-[10px] md:pt-6"
              v-if="this.elective"
            >
              <div
                v-for="(item, index) in elective.syllabus"
                :key="item.id"
                @click="toggleAccordin(index)"
                class="flex flex-col text-left px-[15px] py-[11px] md:px-[25px] cursor-pointer md:py-[20px] gap-2 bg-white border rounded-lg shadow-xl"
              >
                <div class="flex justify-between items-start cursor-pointer">
                  <h3
                    class="text-[14px] md:text-[16px] lg:text-[16px] text-[#18479E] font-bold"
                  >
                    {{ item.semester }}
                  </h3>
                  <div>
                    <span
                      v-if="this.activeAccordin !== index"
                      class="pt-[3px] pl-[4px]"
                    >
                      <font-awesome-icon icon="fa-angle-down" class="" />
                    </span>
                    <span v-else class="pt-[3px] pl-[4px]">
                      <font-awesome-icon icon="fa-angle-up" class="" />
                    </span>
                  </div>
                </div>
                <!-- <div class="flex gap-2">
                  <img
                      src="@/assets/electivepage/check.svg"
                      alt="check image"
                    />
                </div> -->
                <div v-show="activeAccordin === index">
                  <p class="p-2 elctive" v-html="item.content"></p>
                </div>
                <!-- <p
                  class="text-[12px] lg:text-[14px] w-[266px] md:w-[500px] lg:w-[657px] text-[#1f1f1f]"
                  v-show="activeAccordin === index"
                  v-html="item.details"
                ></p> -->
              </div>
              <!-- <div
                class="w-full md:flex md:justify-center md:items-center mt-2 lg:mt-3 hidden"
              >
                <button
                  @click="viewMore"
                  v-if="showMore"
                  class="w-full md:w-[160px] button-cta bg-[#f8c300] border rounded-3xl py-3"
                >
                  Load More
                </button>
                <button
                  @click="viewLess"
                  v-else
                  class="w-full md:w-[160px] button-cta bg-[#f8c300] border rounded-3xl py-3"
                >
                  Load Less
                </button>
              </div> -->
            </div>
          </div>
          <!-- Syllabus end -->
          <!-- Fee Structure start -->
          <div v-if="activeCourse === 'Fee Structure'">
            <div class="flex flex-col gap-3 md:flex-row md:justify-center">
              <!-- indian students  -->
              <div
                class="flex flex-col gap-1 p-5 lg:px-5 lg:py-6 bg-white courseShadow text-left w-full lg:w-[500px]"
              >
                <h2 class="text-[18px] lg:text-[22px] font-bold uppercase">
                  indian students
                </h2>
                <p class="text-[14px]">For Indian National & SAARC Nations</p>
                <div
                  class="border-b-2 border-slate-200 py-3 text-black font-semibold"
                >
                  <!-- <div class="flex justify-between">
                    <p>Semester Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      ₹ {{ elective.indian_semester_fee }}
                    </p>
                  </div> -->
                  <div class="flex justify-between">
                    <p>Annual Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      ₹ {{ elective.indian_annual_fee }}
                    </p>
                  </div>
                  <div class="flex justify-between">
                    <p>Total Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      ₹ {{ elective.indian_total_fee }}
                    </p>
                  </div>
                </div>
                <p class="text-black font-bold text-[14px] py-2">Note</p>
                <div class="flex flex-col gap-2">
                  <p>
                    University Registration Fee:<span
                      class="text-black font-bold text-[14px]"
                    >
                      ₹ {{ elective.indian_university_registration_fee }}</span
                    >
                  </p>
                  <p>
                    Examination Fee per year:
                    <span class="text-black font-bold text-[14px]">
                      ₹ {{ elective.indian_exam_fee }}</span
                    >
                  </p>
                  <p>
                    Registration Fee for SAARC countries:
                    <span class="text-black font-bold text-[14px]"
                      >₹ {{ elective.indian_registration_fee_for_saarc }}</span
                    >
                  </p>
                  <p>
                    Exam Fee Per Year for SAARC countries:
                    <span class="text-black font-bold text-[14px]"
                      >₹ {{ elective.indian_exam_fee_for_saarc }}</span
                    >
                  </p>
                </div>
              </div>
              <!-- foreign students -->
              <div
                class="flex flex-col gap-1 p-5 lg:px-5 lg:py-6 bg-white courseShadow text-left w-full lg:w-[500px]"
              >
                <h2 class="text-[18px] lg:text-[22px] font-bold">
                  FOREIGN STUDENTS
                </h2>
                <p class="text-[14px]">For Foreign Students</p>
                <div
                  class="border-b-2 border-slate-200 py-3 text-black font-semibold"
                >
                  <!-- <div class="flex justify-between">
                    <p>Semester Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      $ {{ elective.foregin_semester_fee }}
                    </p>
                  </div> -->
                  <div class="flex justify-between">
                    <p>Annual Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      $ {{ elective.foregin_annual_fee }}
                    </p>
                  </div>
                  <div class="flex justify-between">
                    <p>Total Fee Plan</p>
                    <p class="text-[#18479e] text-[14px] font-bold">
                      $ {{ elective.foregin_total_fee }}
                    </p>
                  </div>
                </div>
                <p class="text-black font-bold text-[14px] py-2">Note</p>
                <div class="flex flex-col gap-2">
                  <p>
                    University Registration Fee:<span
                      class="text-black font-bold text-[14px]"
                      >$
                      {{ elective.foregin_university_registration_fee }}</span
                    >
                  </p>
                  <p>
                    Examination Fee per year:
                    <span class="text-black font-bold text-[14px]"
                      >$ {{ elective.foregin_exam_fee }}</span
                    >
                    is applicable
                  </p>
                  <!-- <p>
                    Registration Fee for SAARC countries:
                    <span class="text-black font-semibold">₹ 3,750.00</span>
                  </p>
                  <p>
                    Exam Fee Per Year for SAARC countries:
                    <span class="text-black font-semibold">₹ 4,500.00</span>
                  </p> -->
                </div>
              </div>
            </div>
          </div>
          <!-- Fee Structure end -->

          <!-- Eligibility start -->
          <div
            v-if="activeCourse === 'Eligibility'"
            class="flex flex-col gap-3 p-4 text-left"
          >
            <div
              class="flex flex-col gap-5 lg:flex-row w-full lg:max-w-6xl lg:mx-auto"
            >
              <div class="w-full lg:w-1/2">
                <img
                  src="@/assets/electivepage/eligibility.webp"
                  alt="eligibility"
                />
              </div>
              <div
                class="w-full lg:w-1/2 text-left flex justify-center items-center"
              >
                <p class="text-[14px]">
                  Pass in an undergraduate (Bachelor) program of a minimum
                  duration of three (3) years with a minimum aggregate of 45% or
                  an equivalent letter/numerical grade in any stream from a
                  UGC-recognized university.
                </p>
              </div>
            </div>
          </div>
          <!-- Eligibility end -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AdvancedCertificate",
  props: ["elective"],
  data() {
    return {
      programsList: ["Project Management", "Data Science and Analytics"],
      programsproject: [
        {
          id: 1,
          title: "Project Management",
          descriptionChange:
            "<p>The Advanced Certificate Program in {{program.title}} will take you through an insightful journey in the following courses:</p>",
          courses:
            "Lean and Six Sigma Project Risk Management Project Monitoring and Information Systems Project Team Building, Evaluation and Control",
          description:
            "<p>Projects – there was a time when you would associate projects with only infrastructure and civil engineering. The definition of the project has transformed since then and every company today executes projects specific to their strategy. Project management involves determining strategies to evaluate and understand project requirements, bring required resources on board, and monitor the progress. There are over 22 million job opportunities in the field of project management over the next five years </p><br><p>The Advanced Certificate Program in Project Management will build a thorough understanding of identifying and evaluating projects based on multiple facto ₹ It will equip you with skills required to excel in the field of project management including analytical skills, team-building skills, and conflict resolution skills. </p>",
        },
      ],
      programsData: [
        {
          id: 1,
          title: "Data Science and Analytics",
          description:
            "<p>Data is said to be the new oil. And rightly so, because if you have data and can identify meaningful patterns and trends to use as a strategy for business growth, success is definite. The data science industry in India has witnessed a growth of over 30% and is expected to grow at a similar pace in the coming yea ₹ The industry today needs managers who not only understand business and strategy but also have the ability to analyze data and use the same to devise better strategies.</p><br><p>The Advanced Certificate Program in Data Science and Marketing Analytics will build a thorough understanding of the concepts of data science and data metrics. Digital marketing, social media marketing, and other online advertising tools. It will equip you with hands-on expertise on the various factors that drive e-commerce marketing.</p>",
          descriptionChange:
            "<p>The Advanced Certificate Program in {{program.title}} will take you through an insightful journey in the following courses:</p>",
          courses:
            "Introduction to Business Analytics Predictive Analysis using Machine Learning Web and Social Media Analytics Includes Python for Data Science",
        },
      ],
      activeProgram: "Project Management",
      courseDetails: ["Syllabus", "Fee Structure", "Eligibility"],
      activeCourse: "Syllabus",
      faqToShow: 4,
      activeAccordin: "1st Semester",
      syllabus: [
        {
          id: 1,
          title: "1st Semester",
          detailsSyllabus: [
            "Management Concepts and Practices",
            "Principles of Economics and Markets",
            "Managerial Effectiveness and Ethics",
            "Accounting and Finance",
            "Organizational Behavior and Human",
            "Resources Management",
            "Quantitative Techniques and Analytics",
          ],
        },
        {
          id: 2,
          title: "2nd Semester",
          detailsSyllabus: [
            "Entrepreneurship",
            "Marketing Management and Research",
            "Operations Management",
            "Consumer Behavior",
            "Supply Chain Management",
            "Sales and Distribution Management",
          ],
        },
        {
          id: 3,
          title: "3rd Semester",
          // detailsSyllabus: [
          //   "Business Environment and Strategy",
          //   "Project Management",
          //   "Agricultural and Rural Marketing",
          //   "Integrated Marketing Communications",
          //   "Services Marketing and CRM",
          //   "Open Elective Course",
          // ],
          detailsSyllabus: ["To be Disclosed"],
        },
        {
          id: 4,
          title: "4th Semester",
          // detailsSyllabus: [
          //   "Global Marketing",
          //   "B2B Marketing",
          //   "Retail Marketing and Brand Management",
          //   "Digital Marketing and Data Analytics",
          //   "Cross-Functional Elective Course",
          //   "Master Thesis / Project *",
          // ],
          detailsSyllabus: ["To be Disclosed"],
        },
      ],
      // feeStructure: [
      //   {
      //     id:1,
      //     student:'INDIAN STUDENTS',
      //     descript:'',
      //     semFee:'',
      //     annualFee:'',
      //     totalFee:'',
      //     registerFee:'',
      //     examFee:'',
      //     registerFeeOther:'',
      //     examFeeOther:'',
      //   },
      //   {
      //     id:1,
      //     student:'',
      //     descript:'',
      //     semFee:'',
      //     annualFee:'',
      //     totalFee:'',
      //     registerFee:'',
      //     examFee:'',
      //     registerFeeOther:'',
      //     examFeeOther:'',
      //   },
      // ],
    };
  },
  computed: {
    faqItem() {
      return this.syllabus.slice(0, this.faqToShow);
    },
    showMore() {
      return this.faqToShow <= this.syllabus.length;
    },
  },
  methods: {
    toggleAccordin(index) {
      if (index === this.activeAccordin) {
        this.activeAccordin = null;
      } else {
        this.activeAccordin = index;
      }
    },
    // viewMore() {
    //   this.faqToShow += 2;
    // },
    // viewLess() {
    //   this.faqToShow = 3;
    // },
  },
};
</script>

<style scoped>
.shadow {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.courseShadow {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
